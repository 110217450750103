import { Alert, Snackbar } from "@mui/material";
import { NotificationAlertContext } from "providers/notification";
import { useContext } from "react";

const NotificationAlert = () => {
  const { notificationProps, clearNotification } = useContext(NotificationAlertContext);

  return (
    <Snackbar
      open={notificationProps.isAlertVisible}
      autoHideDuration={notificationProps.alertDuration}
      onClose={clearNotification}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert
        variant="filled"
        onClose={clearNotification}
        severity={notificationProps.severity}
        sx={{
          width: "100%",
          display: !!notificationProps.message ? "flex" : "none",
          alignItems: "center",
        }}
      >
        {notificationProps.message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationAlert;
