import { RootState } from "store";

import { SliceStatus } from "../utils";
import { profileAddressAdapter } from "./profileAddressesSlice";

const { selectAll, selectById } = profileAddressAdapter.getSelectors(
  (state: RootState) => state.profileAddresses
);

export const selectProfileAddresses = selectAll;

export const selectProfileAddressById = (id: number) => (state: RootState) => selectById(state, id);

export const selectAreProfileAddressesLoading = (state: RootState) =>
  state.profileAddresses.status === SliceStatus.LOADING;
