import { Article } from "types/Article";
import http from "utils/http";

export type ArticleSlotArguments = {
  serviceVariant: number;
  start: string;
  end: string;
  staff?: number | null;
};

export type ArticleArguments = {
  serviceVariant?: number | null;
  packageVariant?: number | null;
  slots: ArticleSlotArguments[];
};

export type CreateAppointmentArguments = {
  outlet: Nullable<number>;
  date: Nullable<string>;
  notes: string;
  articles: ArticleArguments[];
};

export type CancelAppointmentArguments = {
  id: string;
  status: string;
};

export type AppointmentResponse = {
  appointment: Appointment;
  articles: Article[];
  slots: ArticleSlot[];
  payment?: Payment;
};

export type createHomeServiceAppointmentArguments = CreateAppointmentArguments & {
  address: number;
}

const appointmentsApi = {
  createAppointment: async (data: CreateAppointmentArguments) =>
    http.post<AppointmentResponse>("ob/appointments/appointments/", data),
  createHomeServiceAppointment: async (data: CreateAppointmentArguments) =>
    http.post<AppointmentResponse>("ob/appointments/home-services-appointments/", data),

  cancelAppointment: async ({ status, id }: CancelAppointmentArguments) =>
    http.put<AppointmentResponse>(`ob/appointments/appointments/${id}/`, { status }),
};

export default appointmentsApi;
