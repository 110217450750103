import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { useBookingContext } from "../bookingContext";
import { BookingSlot } from "../bookingPageTypes";
import { slotsApi } from "./slotPickerHelpers";

/**
 * Hook that returns available slots for the given date
 *
 * @param stringDate The date you want available slots for
 */
export default function useAvailableSlots(stringDate: string) {
  const {
    state: { serviceEmployees, packageVariantId },
  } = useBookingContext();

  const serviceVariantId = serviceEmployees[0][0];

  const { id: outletId } = useParamOutlet();

  const [isLoading, setIsLoading] = useState(true);

  const [availableSlots, setAvailableSlots] = useState<BookingSlot[]>([]);

  useEffect(() => {
    // TODO: Debounce fetching function
    setIsLoading(true);

    if (packageVariantId) {
      slotsApi
        .getPackageSlots({
          date: stringDate,
          packageVariantId,
          outletId,
        })
        .then((response) => {
          const availableSlots: BookingSlot[] = response.data.map((packageSlots) => {
            const entries = Object.entries(packageSlots).sort(
              ([_a, { time: timeA }], [_b, { time: timeB }]) => {
                if (DateTime.fromISO(timeB) >= DateTime.fromISO(timeA)) return -1;
                if (DateTime.fromISO(timeB) <= DateTime.fromISO(timeA)) return 1;
                return 0;
              }
            );

            return {
              ...entries[0][1],
              employees: [...entries.map(([_, { employees }]) => employees)],
            };
          });
          setAvailableSlots(availableSlots);
          setIsLoading(false);
        });
    } else {
      slotsApi
        .getServiceSlots({
          date: stringDate,
          serviceVariantId,
          outletId,
        })
        .then((response) => {
          const availableSlots: BookingSlot[] = response.data.map((slot) => ({
            ...slot,
            employees: [[...slot.employees]],
          }));

          setAvailableSlots(availableSlots);
          setIsLoading(false);
        });
    }
  }, [outletId, packageVariantId, stringDate, serviceVariantId]);

  return { availableSlots, areSlotsLoading: isLoading };
}
