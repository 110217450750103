import { Button, Stack, Typography } from "@mui/material";
import { LocationPinIcon } from "assets/icons/LocationPinIcon";
import ClientAddressesModal from "components/ClientAddressesModal";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectAuthIsLoggedIn } from "store/selectors";
import { getProfileAddresses } from "store/slices/profileAddresses/profileAddressesSlice";

import AddressDetailsSection from "./AddressDetailsSection";

type AppointmentAddressSectionProps = {
  selectedAreaId?: Nullable<number>;
  selectedAddressId: Nullable<number>;
  handleChangeSelectedAddressId?: (addressId: Nullable<number>) => void;
  isAddressModalOpen?: boolean;
  setIsAddressModalOpen?: (isOpen: boolean) => void;
};

const AppointmentAddressSection = ({
  selectedAreaId,
  selectedAddressId,
  handleChangeSelectedAddressId,
  isAddressModalOpen,
  setIsAddressModalOpen,
}: AppointmentAddressSectionProps) => {
  const { t } = useTranslation(["common", "reviewAppointmentPage"]);

  const isLoggedIn = useSelector(selectAuthIsLoggedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileAddresses({}));
    }
  }, [isLoggedIn, dispatch]);

  const handleClickOpenAddressModal = () => {
    if (!isLoggedIn) {
      navigate("/login");

      return;
    }

    setIsAddressModalOpen && setIsAddressModalOpen(true);
  };

  const handleCloseAddressModal = () => {
    setIsAddressModalOpen && setIsAddressModalOpen(false);
  };

  const handleChangeAddress = (addressId: number | null) => {
    handleChangeSelectedAddressId && handleChangeSelectedAddressId(addressId);
  };

  const handleContinueClick = () => {
    handleCloseAddressModal();
  };

  return (
    <>
      <ClientAddressesModal
        isOpen={!!isAddressModalOpen}
        onClose={handleCloseAddressModal}
        selectedAreaId={selectedAreaId || null}
        selectedAddressId={selectedAddressId}
        handleChangeAddress={handleChangeAddress}
        handleContinueClick={handleContinueClick}
      />

      <Stack gap={2}>
        <Typography variant="primary" fontWeight="bold" color="#848FA1" textTransform="uppercase">
          {t("reviewAppointmentPage:yourAddress")}
        </Typography>

        {selectedAddressId && !!handleChangeAddress ? (
          <AddressDetailsSection
            selectedAddressId={selectedAddressId}
            handleChange={handleChangeSelectedAddressId ? handleClickOpenAddressModal : undefined}
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<LocationPinIcon />}
            fullWidth
            onClick={handleClickOpenAddressModal}
          >
            {t("reviewAppointmentPage:pickAnAddress")}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default AppointmentAddressSection;
