import { Box, Stack, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { formatPrice } from "helpers/formatPrice";
import { DecimalStrategy, KUWAITI_DINAR } from "poc/currency";
import { useTranslation } from "react-i18next";

type PriceBoxProps = {
  value: number;
  hideCurrency?: boolean;
  decimalStrategy?: DecimalStrategy;
  shouldShowPriceFrom?: boolean;
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  variant?: TypographyProps["variant"];
  isFree?: boolean;
  upToPrice?: Nullable<number>;
  hasMoreThanOneVariant?: boolean;
  quantity?: number;
  flexDirection?: "row" | "column";
};

export default function PriceBox({
  isFree = false,
  value,
  hideCurrency = false,
  decimalStrategy = "currency_default",
  shouldShowPriceFrom = false,
  variant = "primary",
  sx = {},
  textSx = {}, // Hasan: I don't like this
  upToPrice,
  hasMoreThanOneVariant,
  quantity,
  flexDirection = "column",
}: PriceBoxProps) {
  const { t, i18n } = useTranslation(["currency", "common"]);

  const direction = i18n.dir();

  const [currencyCode, currencyDecimals] = selectCurrencyFromOrgOrSomethingLikeThat();

  const stringifiedPrice = formatPrice(value, decimalStrategy, currencyDecimals);

  if (hideCurrency) {
    return <>{stringifiedPrice}</>;
  }

  return (
    <Stack alignItems="center" flexDirection={flexDirection}>
      {quantity && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography>{quantity}</Typography>

          <Typography variant="secondary" color="#848FA1">
            x
          </Typography>
        </Stack>
      )}

      {hasMoreThanOneVariant && (
        <Typography alignSelf="flex-end" px={2} fontSize={12} fontWeight={500} color="#6C798F">
          {t("common:from")}
        </Typography>
      )}

      <Box
        px={2}
        display="flex"
        columnGap={0.5}
        sx={{ ...sx, whiteSpace: "nowrap", flexDirection: isFree ? "column" : "row" }}
      >
        {!hasMoreThanOneVariant && shouldShowPriceFrom && (
          <Typography sx={{ ...textSx }}>{t("common:from")}</Typography>
        )}

        <Typography variant={variant} sx={{ ...textSx }}>
          {isFree
            ? t("free")
            : direction === "ltr"
            ? `${t(currencyCode)} ${stringifiedPrice}`
            : `${stringifiedPrice} ${t(currencyCode)}`}
        </Typography>

        {!!upToPrice && (
          <>
            <Typography sx={{ ...textSx }}>{t("common:to")}</Typography>
            <Typography sx={{ ...textSx }}>
              {formatPrice(upToPrice, decimalStrategy, currencyDecimals)}
            </Typography>
          </>
        )}

        {isFree && value && (
          <Typography sx={{ textDecoration: "line-through" }} variant="secondary" color="gray">
            {direction === "ltr"
              ? `${t(currencyCode)} ${stringifiedPrice}`
              : `${stringifiedPrice} ${t(currencyCode)}`}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

// POC: Leaving the possibility of using different currencies here
const selectCurrencyFromOrgOrSomethingLikeThat = () => KUWAITI_DINAR;
