import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { NumberOfPeopleButtons } from "./NumberOfPeopleModal";

type NumberOfPeopleSelectorProps = {
  appointmentNumberOfPeople: number;
  selectedPurchasableNumberOfPeople: Nullable<number>;
  setSelectedPurchasableNumberOfPeople: (numOfPeople: number) => void;
};

export default function NumberOfPeopleSelector({
  appointmentNumberOfPeople,
  selectedPurchasableNumberOfPeople,
  setSelectedPurchasableNumberOfPeople,
}: NumberOfPeopleSelectorProps) {
  const { t } = useTranslation(["outletPage", "common"]);
  return (
    <Box display="flex" flexDirection="column" gap={1} py={2}>
      <Typography fontWeight={700}>{t("howManyPeopleWillHaveThisService")}</Typography>
      <NumberOfPeopleButtons
        maxNumberOfPeople={appointmentNumberOfPeople}
        numOfPeople={selectedPurchasableNumberOfPeople}
        setNumberOfPeople={setSelectedPurchasableNumberOfPeople}
      />
    </Box>
  );
}
