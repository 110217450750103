import { MagnifyingGlass } from "@bookpeep/ui";
import { Box, Grid, Tab, Tabs, styled } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import useTranslatedValue from "hooks/useTranslatedValue";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectAllServiceCategories,
  selectOrganization,
  selectPackagesByOutletId,
  selectPopularPackagesByOutletId,
  selectPopularServicesByOutletIdAndEmployeeId,
  selectServicesByOutletId,
} from "store/selectors";
import { checkIsHomeServiceOrganization } from "utils/organization";

import CategoryServices from "./CategoryServices";
import { TYPES } from "./constants";
import PackageList from "./PackageList";
import PopularList from "./PopularList";
import SearchTab from "./SearchTab";

function TabPanel(props: any) {
  const { children, value, index } = props;

  return <>{value === index && <TabPanelWrapper>{children}</TabPanelWrapper>}</>;
}

const ServicesList = () => {
  const { t } = useTranslation("outletPage");
  const { getTranslatedValue } = useTranslatedValue();

  const paramOutlet = useParamOutlet(true);

  const { employeeId } = useParams();

  const isEmployeeView = !!employeeId;

  const [value, setValue] = useState(1);

  const categories = useSelector(selectAllServiceCategories);

  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const outletPackages = useSelector(selectPackagesByOutletId(paramOutlet?.id || 0));

  const popularServices = useSelector(
    selectPopularServicesByOutletIdAndEmployeeId(
      paramOutlet?.id || 0,
      Number(employeeId) || undefined
    )
  );

  const popularPackages = useSelector(selectPopularPackagesByOutletId(paramOutlet?.id || 0));

  const allServices = useSelector(selectServicesByOutletId(paramOutlet?.id || 0));
  const availableServiceCategoryIds = allServices?.map((service) => service.category);
  const categoriesWithServices = categories.filter((category) =>
    availableServiceCategoryIds.includes(category.id)
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const hasPopular =
    ((isEmployeeView && !!popularServices.length) ||
      (!isEmployeeView && !!(popularServices.length || popularPackages.length))) &&
    !isHomeService;

  const hasOffers = !!outletPackages.length && !isEmployeeView;

  const hasAllServices = !!allServices.length && isHomeService;

  const indexIncrement = [hasPopular, hasOffers, hasAllServices].filter(Boolean).length + 1;

  return (
    <>
      <Box
        display="flex"
        width="100%"
        sx={{
          position: "sticky",
          top: 50,
          zIndex: 50,
        }}
        bgcolor="white"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <TabStyle
            key="search"
            icon={<MagnifyingGlass />}
            id="simple-tab-popular"
            aria-controls="simple-tabpanel-popular"
          />

          {hasPopular && !isHomeService && (
            <TabStyle
              key="popular"
              label={t("glossary:popular")}
              id="simple-tab-popular"
              aria-controls="simple-tabpanel-popular"
            />
          )}

          {hasOffers && (
            <TabStyle
              key="offers"
              label={`${isHomeService ? t("glossary:offers") : t("glossary:packages")} 🔥`}
              id="simple-tab-offers"
              aria-controls="simple-tabpanel-offers"
            />
          )}

          {isHomeService && (
            <TabStyle
              key="allServices"
              label={t("glossary:allServices")}
              id="simple-tab-allServices"
              aria-controls="simple-tabpanel-allServices"
            />
          )}

          {categoriesWithServices.map((category, index) => (
            <TabStyle
              key={category.id}
              label={getTranslatedValue(category.t.name)}
              id={`simple-tab-${index + indexIncrement}`}
              aria-controls={`simple-tabpanel-${index + indexIncrement}`}
            />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ padding: (theme) => theme.spacing(2) }}>
        <TabPanel key="search" value={value} index={0}>
          <SearchTab />
        </TabPanel>

        {hasPopular && (
          <TabPanel key="popular" value={value} index={1}>
            <Grid container spacing={1}>
              <PopularList isEmployeeView={isEmployeeView} />
            </Grid>
          </TabPanel>
        )}

        {hasOffers && !isEmployeeView && (
          <TabPanel key="offers" value={value} index={hasPopular ? 2 : 1}>
            <Grid container spacing={1}>
              <PackageList />
            </Grid>
          </TabPanel>
        )}

        {isHomeService && (
          <TabPanel
            key="allServices"
            value={value}
            index={hasPopular && hasOffers ? 3 : hasOffers || hasPopular ? 2 : 1}
          >
            <Grid container spacing={1}>
              <CategoryServices key="allServices" type={TYPES.SERVICES} />
            </Grid>
          </TabPanel>
        )}

        {categoriesWithServices.map((category, index) => (
          <TabPanel key={category.id} value={value} index={index + indexIncrement}>
            <CategoryServices key={category.id} category={category} type={TYPES.SERVICES} />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

const TabStyle = styled(Tab)(({ theme }) => ({
  padding: 0,
  marginRight: theme.spacing(1.5),
  marginLeft: theme.spacing(1.5),
}));

const TabPanelWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0, 0),
}));

export default ServicesList;
