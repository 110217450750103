import { ChevronDown } from "@bookpeep/ui";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { EyeIcon } from "assets/icons/Eye";
import { LocationIcon } from "assets/icons/Location";
import OutletInformationModal from "components/OutletInformationModal";
import OutletSelectorModal from "components/OutletSelectorModal";
import { getImageSrc } from "helpers/getImageSrc";
import useParamOutlet from "hooks/useParamOutlet";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectAllOutlets, selectIsOutletsLoading, selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";
import { checkIsHomeServiceOrganization } from "utils/organization";

import OutletBanner from "./OutletBanner";

const OutletPageHeader = () => {
  const [isOutletModalOpen, setIsOutletModalOpen] = useState<boolean>(false);

  const [isOutletInformationModalOpen, setIsOutletInformationModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const outlet = useParamOutlet();
  const hasPromptUserWhenMultipleOutlets = useRef(false);

  const org = useSelector(selectOrganization);
  const outlets = useSelector(selectAllOutlets);
  const areOutletsLoading = useSelector(selectIsOutletsLoading);

  const { avatar } = org;

  const hasOpenedOutletModalInCurrentSession =
    sessionStorage.getItem("hasOpenedOutletModal") === "true";

  useEffect(() => {
    if (!areOutletsLoading) {
      if (
        outlets.length > 1 &&
        !hasPromptUserWhenMultipleOutlets.current &&
        !hasOpenedOutletModalInCurrentSession
      ) {
        setIsOutletModalOpen(true);
        hasPromptUserWhenMultipleOutlets.current = true;
        sessionStorage.setItem("hasOpenedOutletModal", "true");
      } else {
        hasPromptUserWhenMultipleOutlets.current = true;
      }
    }
  }, [areOutletsLoading, hasOpenedOutletModalInCurrentSession, outlets.length]);

  if (!outlet || !org) return null;

  const handleOutletSelect = (outlet: Outlet) => {
    if (org?.id && org?.name) {
      trackEvent("Location Changed", { id: org.id, name: org.name }, {});
    }
    navigate(`/${outlet.slug}`);
  };

  const outletName = outlet?.address?.area
    ? `${outlet.name} - ${outlet.address.area}`
    : outlet?.name || "";

  const shouldShowOutletInformation = outlet.contactNumbers.length || outlet.address.area;

  const isHomeService = checkIsHomeServiceOrganization(org.industry!);

  return (
    <Box>
      <Box>
        <OutletBanner
          banners={[{ id: 0, image: outlet?.primaryImage, outlet: outlet?.id }, ...outlet?.images]}
        />
      </Box>

      <Box mt={-9} pl={2} pb={2}>
        <Avatar sx={{ width: "59px", height: "59px" }} src={getImageSrc(avatar)} />
      </Box>

      <Box display="flex" p={2}>
        <OutletSelectorModal
          outlets={outlets}
          open={isOutletModalOpen}
          onClose={() => setIsOutletModalOpen(false)}
          onSelect={handleOutletSelect}
        />

        <OutletInformationModal
          open={isOutletInformationModalOpen}
          onClose={() => setIsOutletInformationModalOpen(false)}
          outlet={outlet}
          organization={org}
        />

        <Box flexGrow={1} rowGap={0.5}>
          <Typography variant="h3">{org.name}</Typography>

          {!isHomeService &&
            (outlets.length > 1 ? (
              <Button
                onClick={() => setIsOutletModalOpen(true)}
                variant="text"
                endIcon={<ChevronDown fontSize="small" />}
              >
                <Box display="flex" alignItems="center" columnGap={0.5}>
                  <LocationIcon fontSize="small" sx={{ width: "1rem" }} />

                  <Typography align="left" variant="primary">
                    {outletName}
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Box display="flex" alignItems="center" columnGap={1}>
                <LocationIcon
                  fontSize="small"
                  color="primary"
                  sx={{ fontSize: "0.875rem", fontWeight: 400 }}
                />

                <Typography variant="primary" color="primary">
                  {outletName}
                </Typography>
              </Box>
            ))}
        </Box>

        {shouldShowOutletInformation && (
          <Box
            display="flex"
            alignItems="center"
            color="primary.main"
            sx={{
              minWidth: 38,
              minHeight: 38,
              textAlign: "right",
            }}
            onClick={() => {
              if (org?.id && org?.name) {
                trackEvent("Location info viewed", { id: org.id, name: org.name }, {});
              }
              setIsOutletInformationModalOpen(true);
            }}
          >
            <EyeIcon fontSize="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OutletPageHeader;
