import { Box, Button, Typography } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { useTranslation } from "react-i18next";

type HomeServiceConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  description: string;
};

const HomeServiceConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  description,
}: HomeServiceConfirmationModalProps) => {
  const { t } = useTranslation("outletPage");

  return (
    <DrawerModal
      title={t("startNewBooking")}
      showClose
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "20vh", padding: "16px" } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="space-between"
        height="100%"
        rowGap={2}
      >
        <Typography variant="primary" color="#8E98A9">
          {description}
        </Typography>

        <Button fullWidth variant="contained" color="error" size="large" onClick={onConfirm}>
          {t("yesStartOver")}
        </Button>

        <Button fullWidth variant="text" color="primary" size="large" onClick={onClose}>
          {t("cancel")}
        </Button>
      </Box>
    </DrawerModal>
  );
};
export default HomeServiceConfirmationModal;
