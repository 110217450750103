import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import { BookingSlot } from "pages/BookingPage/bookingPageTypes";
import { slotsApi } from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import { useEffect, useState } from "react";

type usePurchasableAvailabilityType = {
  date: string;
  serviceVariantId?: number;
  packageVariantId?: number;
};

/**
 * Hook that returns available slots for the given date
 *
 * @param data which includes a ISO Date, service variant id or package variant id
 *
 */
export default function usePurchasableAvailability(data: usePurchasableAvailabilityType) {
  const { date, serviceVariantId, packageVariantId } = data;

  const { id: outletId } = useParamOutlet();

  const [isLoading, setIsLoading] = useState(true);

  const [availableSlots, setAvailableSlots] = useState<BookingSlot[]>([]);

  useEffect(() => {
    // TODO: Debounce fetching function
    setIsLoading(true);

    if (packageVariantId) {
      slotsApi
        .getPackageSlots({
          date,
          packageVariantId,
          outletId,
        })
        .then((response) => {
          const availableSlots: BookingSlot[] = response.data.map((packageSlots) => {
            const entries = Object.entries(packageSlots).sort(
              ([_a, { time: timeA }], [_b, { time: timeB }]) => {
                if (DateTime.fromISO(timeB) >= DateTime.fromISO(timeA)) return -1;
                if (DateTime.fromISO(timeB) <= DateTime.fromISO(timeA)) return 1;
                return 0;
              }
            );

            return {
              ...entries[0][1],
              employees: [...entries.map(([_, { employees }]) => employees)],
            };
          });

          setAvailableSlots(availableSlots);
          setIsLoading(false);
        });
    } else if (serviceVariantId) {
      slotsApi
        .getServiceSlots({
          date,
          serviceVariantId,
          outletId,
        })
        .then((response) => {
          const availableSlots: BookingSlot[] = response.data.map((slot) => ({
            ...slot,
            employees: [[...slot.employees]],
          }));

          setAvailableSlots(availableSlots);
          setIsLoading(false);
        });
    }
  }, [outletId, packageVariantId, date, serviceVariantId]);

  return { availableSlots, areSlotsLoading: isLoading };
}
