import { Close, MagnifyingGlass } from "@bookpeep/ui";
import { IconButton, InputAdornment, TextField, styled } from "@mui/material";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type SearchFieldProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  variant?: "standard" | "outlined" | "filled" | undefined;
};

export default function SearchField({ value, onChange, onClear, variant }: SearchFieldProps) {
  const { t } = useTranslation();

  return (
    <TextField
      value={value}
      onChange={onChange}
      variant={variant || "filled"}
      hiddenLabel
      size="small"
      fullWidth
      placeholder={t("search")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlass />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <ClearIconButton onClick={onClear}>
              <Close fontSize="small" />
            </ClearIconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

const ClearIconButton = styled(IconButton)(() => ({
  color: "#4E5D7824",
}));
