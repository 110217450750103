import { Close } from "@bookpeep/ui";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import { UserIcon } from "assets/icons/User";
import HomeServiceConfirmDeleteArticleModal from "components/HomeServiceConfirmDeleteArticleModal";
import PriceBox from "components/PriceBox";
import dontForwardProps from "helpers/styles/dontForwardProps";
import useParamOutlet from "hooks/useParamOutlet";
import useTranslatedValue from "hooks/useTranslatedValue";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "store";
import {
  selectCart,
  selectPackageById,
  selectPackageVariantsByPackageId,
  selectServiceById,
  selectServiceVariantsByServiceId,
} from "store/selectors";
import { removeArticleFromCart } from "store/slices/cart/cartSlice";
import { getLatestCartArticleSlotWithArticleIndex } from "store/slices/cart/getLatestArticleSlotWithArticleIndex";

import OutletBookingContext from "../outletContext";
import cartApi from "store/slices/cart/cartApi";

type PurchasableCardProps = {
  serviceId?: number;
  variantId?: number;
  packageId?: number;
};

const PurchasedArticleCard = ({ serviceId, packageId, variantId }: PurchasableCardProps) => {
  const { getTranslatedValue } = useTranslatedValue();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const dispatch = useDispatch();
  const outlet = useParamOutlet();

  const { selectedPurchasable, setSelectedPurchasable, setSelectedPurchasablePackage } =
    useContext(OutletBookingContext);

  useEffect(() => {
    setSelectedPurchasable(null);
    setSelectedPurchasablePackage(null);
  }, [outlet, setSelectedPurchasable, setSelectedPurchasablePackage]);

  const cart = useSelector(selectCart);
  const pkg = useSelector(selectPackageById(packageId || 0));
  const service = useSelector(selectServiceById(serviceId || 0));
  const serviceVariants = useSelector(selectServiceVariantsByServiceId(serviceId || 0));
  const packageVariants = useSelector(selectPackageVariantsByPackageId(packageId || 0));

  const isService = serviceId && !!service && !!serviceVariants.length;

  const isPackage = packageId && !!pkg && !!packageVariants.length;

  const serviceHasOneVariant = isService && serviceVariants.length === 1;

  if (!isPackage && !isService) return null;

  const serviceAndVariantNamesAreIdentical =
    serviceHasOneVariant && serviceVariants[0].name === service.name;

  let title = "";

  if (isService) {
    title =
      serviceAndVariantNamesAreIdentical || !serviceHasOneVariant
        ? getTranslatedValue(service.t.name)
        : `${getTranslatedValue(service.t.name)} - ${getTranslatedValue(
            serviceVariants[0].t.name
          )}`;
  } else if (isPackage) {
    title = getTranslatedValue(pkg.t.name);
  }

  const isBookButtonExpanded = isPackage
    ? selectedPurchasable === packageVariants[0].id
    : serviceVariants.map((serviceVariant) => serviceVariant.id).includes(selectedPurchasable || 0);

  const cartItem = isPackage
    ? cart.articles.find(
        (article) =>
          article.packageVariant &&
          packageVariants.some((packageVariant) => packageVariant.id === article.packageVariant)
      )
    : cart.articles.find(
        (article) =>
          article.serviceVariant &&
          serviceVariants.some((serviceVariant) => serviceVariant.id === article.serviceVariant)
      );

  const cartIndex = cart.articles.findIndex(
    (article) => !isPackage && article.serviceVariant === variantId
  );

  const cartPackageIndex = cart.articles.findIndex(
    (article) => isPackage && article.packageVariant === variantId
  );

  const modifiedArticles = cart.articles.map((article, index) => ({ ...article, index }));
  const latestArticle = getLatestCartArticleSlotWithArticleIndex(modifiedArticles);

  const isLatestArticle = latestArticle?.articleIndex === cartIndex;

  return (
    <>
      <StyledBox isSelected={false} sx={{ backgroundColor: "#E4FAF7" }}>
        <Box
          px={2}
          pt={2}
          pb={isBookButtonExpanded ? 0 : 2}
          display="flex"
          flexDirection="column"
          rowGap={1}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" rowGap={1} width="45%">
              <Typography variant="primary" color="black" fontWeight="bold">
                {serviceHasOneVariant || isPackage
                  ? title
                  : title +
                    " - " +
                    getTranslatedValue(serviceVariants.find((v) => v.id === variantId)?.t.name)}
              </Typography>

              <Box display="flex" gap={1}>
                <UserIcon fontSize="small" />
                <Typography>{cartItem?.numberOfPeople}</Typography>
              </Box>
            </Box>

            <Box display="flex" gap={1} alignItems="center">
              <Typography>{cartItem?.numberOfPeople}</Typography>
              <Typography>x</Typography>
              <PriceBox
                value={cartItem?.totalPrice!}
                decimalStrategy="trim_zeros"
                variant="primary"
                sx={{ padding: 0 }}
              />
              <Close
                fontSize="small"
                sx={{
                  backgroundColor: "#6C798F",
                  borderRadius: 20,
                  color: "white",
                  padding: 0.1,
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (cartIndex !== null && cartIndex >= 0) {
                    if (isLatestArticle) {
                      dispatch(removeArticleFromCart(cartIndex));
                      if (cartItem?.draftIds && cartItem.draftIds.length > 0) {
                        await cartApi.deleteDraftArticles(cartItem?.draftIds);
                      }
                    } else {
                      setShowConfirmationModal(true);
                    }
                  } else if (cartPackageIndex !== null && cartPackageIndex >= 0) {
                    if (latestArticle?.articleIndex === cartPackageIndex) {
                      dispatch(removeArticleFromCart(cartPackageIndex));
                      if (cartItem?.draftIds && cartItem.draftIds.length > 0) {
                        await cartApi.deleteDraftArticles(cartItem?.draftIds);
                      }
                    } else {
                      setShowConfirmationModal(true);
                    }
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
      </StyledBox>

      {showConfirmationModal && (
        <HomeServiceConfirmDeleteArticleModal
          onConfirm={async () => {
            dispatch(removeArticleFromCart(cartIndex));
            if (cartItem?.draftIds && cartItem.draftIds.length > 0) {
              await cartApi.deleteDraftArticles(cartItem?.draftIds);
            }
            setShowConfirmationModal(false);
          }}
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};

type CustomBoxProps = BoxProps & {
  isSelected: boolean;
};

const StyledBox = styled(
  Box,
  dontForwardProps("isPackage", "isSelected")
)<CustomBoxProps>(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),
  border: "1px solid #D4D6E4",
  borderStyle: "solid",
  overflowX: "clip",
  boxShadow: isSelected ? "0px 2px 24px rgba(0, 0, 0, 0.18)" : "none",

  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
}));

export default PurchasedArticleCard;
