import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import SelectedEmployee from "components/SelectedEmployee";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import SubscriptionRedemptionTag from "pages/AppointmentPage/SubscriptionRedemptionTag";
import AppointmentArticleContainer from "pages/ReviewAppointmentPage/AppointmentArticleContainer";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectEmployeeById,
  selectServiceById,
  selectServiceVariantById,
  selectServiceVariantLongName,
} from "store/selectors";
import { Article } from "types/Article";
import { ServiceVariantPricingOption } from "types/ServiceVariantPricingOption";

type AppointmentServiceArticleType = {
  article: Article;
  slots: ArticleSlot[];

  shouldShowPrice?: boolean;
  shouldShowSelectedEmployeeBox?: boolean;
  isDetailsPage?: boolean;
};

const AppointmentServiceArticle = ({
  article,
  slots,
  shouldShowPrice = true,
  shouldShowSelectedEmployeeBox = true,
}: AppointmentServiceArticleType) => {
  const { t } = useTranslation();

  const serviceVariant = useSelector(selectServiceVariantById(article.serviceVariant || ""));
  const service = useSelector(selectServiceById(serviceVariant?.service || ""));
  const serviceVariantNameKeys = useSelector(
    selectServiceVariantLongName(serviceVariant?.id || -1)
  );

  const employee = useSelector(selectEmployeeById(slots?.[0]?.staff || 0));

  if (!service || !serviceVariant) return <CentredSpinnerBox />;

  const { pricingType } = serviceVariant;

  const shouldShowPriceFrom = pricingType === ServiceVariantPricingOption.From;

  const slot = slots.length ? slots[0] : null;

  const hasStaff = !!employee && slot?.isStaffSelectedByClient;

  return (
    <AppointmentArticleContainer>
      <Box display="flex" flexDirection="column" justifyContent="center" width="100%" rowGap={2}>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column" rowGap={1} height="100%">
            <Typography variant="h4">
              {t(serviceVariantNameKeys[0])}
              {serviceVariantNameKeys?.[1] && ` - ${t(serviceVariantNameKeys[1])}`}
            </Typography>

            {slot && (
              <>
                <Typography variant="primary">{`${fromTofyTimes(
                  DateTime.fromISO(slot.start),
                  DateTime.fromISO(slot.end)
                )} • ${getFormattedDuration(serviceVariant.duration)}`}</Typography>

                {!shouldShowSelectedEmployeeBox && hasStaff && (
                  <Typography variant="primary">{t(employee.t.fullName)}</Typography>
                )}
              </>
            )}
          </Box>

          {shouldShowPrice && (
            <PriceBox
              isFree={!!article.subscriptionRedemption}
              value={Number(serviceVariant.price)}
              decimalStrategy="trim_zeros"
              shouldShowPriceFrom={shouldShowPriceFrom}
              variant="h4"
              textSx={{ color: "black", fontWeight: 400 }}
            />
          )}
        </Box>

        {shouldShowSelectedEmployeeBox && hasStaff && <SelectedEmployee employee={employee} />}

        {article.subscriptionRedemption && (
          <SubscriptionRedemptionTag title={article.subscriptionRedemption.name} />
        )}
      </Box>
    </AppointmentArticleContainer>
  );
};

export default AppointmentServiceArticle;
