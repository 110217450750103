import { Box, Divider, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization, selectServiceById, selectServiceVariantById } from "store/selectors";

import ArticleStaffSelector from "./ArticleStaffSelector";
import { checkIsHomeServiceOrganization } from "utils/organization";

type PackageArticleServiceVariantDetailsProps = {
  index: number;
  slot: CartArticleSlot;
  blockedEmployeeIds: number[];
  handleArticleUpdate: (slot: CartArticleSlot) => void;
};

const PackageArticleServiceVariantDetails = ({
  index,
  slot,
  blockedEmployeeIds,
  handleArticleUpdate,
}: PackageArticleServiceVariantDetailsProps) => {
  const { t } = useTranslation();
  const serviceVariant = useSelector(selectServiceVariantById(slot.serviceVariant));
  const service = useSelector(selectServiceById(serviceVariant?.service || ""));

  const org = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(org?.industry!);

  if (!service || !serviceVariant) return <CentredSpinnerBox />;

  const shouldShowServiceVariantName = service.name !== serviceVariant.name;

  return (
    <>
      {index !== 0 && <Divider variant="fullWidth" />}

      <Box width="100%" display="flex" flexDirection="column" rowGap={1} px={2} pt={2} pb={2}>
        <Typography variant="primary" fontWeight="bold">
          {t(service.t.name)} {shouldShowServiceVariantName && `- ${t(serviceVariant.t.name)}`}
        </Typography>

        <Typography variant="secondary">
          {t("duration")}: {getFormattedDuration(serviceVariant.duration)}
        </Typography>

        {slot && !isHomeService && (
          <ArticleStaffSelector
            slot={slot}
            blockedEmployeeIds={blockedEmployeeIds}
            handleArticleUpdate={handleArticleUpdate}
          />
        )}
      </Box>
    </>
  );
};

export default PackageArticleServiceVariantDetails;
