/**
 * 🎁 Note: This function is used to sort articles by which starts first
 *
 * @param articles
 * @returns
 */

import { DateTime } from "luxon";

function sortArticlesAscending(articles: CartArticle[]): CartArticle[] {
  return [
    ...articles
      .map((article) => {
        let earliestTime: string = "";
        article.slots.forEach((slot) => {
          const slotTime = DateTime.fromISO(slot.start);

          if (!earliestTime || slotTime < DateTime.fromISO(earliestTime))
            earliestTime = slotTime.toISO();
        });
        return { ...article, start: earliestTime };
      })
      .sort((a, b) => DateTime.fromISO(a.start).toMillis() - DateTime.fromISO(b.start).toMillis())
      .map((article) => {
        const { ...rest } = article;
        return { ...rest };
      }),
  ];
}

export default sortArticlesAscending;
