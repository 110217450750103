import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import {
  createAppointment,
  createHomeServiceAppointment,
} from "store/slices/appointments/appointmentsSlice";
import { selectCart, selectCartAddress } from "store/slices/cart/cartSelectors";
import { clearCart } from "store/slices/cart/cartSlice";
import { checkIsHomeServiceOrganization } from "utils/organization";
import { notify } from "utils/SnackbarProvider";

const ConfirmBookingModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(["reviewAppointmentPage", "errors"]);

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const address = useSelector(selectCartAddress);

  const appointment = useSelector(selectCart);

  const handleAppointmentCreate = () => {
    setIsRequestLoading(true);

    const { articles, outlet, ...rest } = appointment;

    const data = {
      outlet: outlet ?? 0,
      ...rest,
      articles: articles.map((article) => {
        const { ...articleRest } = article;
        return { ...articleRest };
      }),
      address: isHomeService ? address : undefined,
    };

    const dispatchAction = isHomeService ? createHomeServiceAppointment : createAppointment;

    dispatch(dispatchAction(data))
      .unwrap()
      .then((response: any) => {
        const { payment } = response;

        setOpen(false);

        dispatch(clearCart());

        // if suddenly this appointment required down payment, because online booking payment rules got updated on the backend before the user confirm booking while he is on the confirmation page
        if (payment?.paymentURL) window.location.href = payment.paymentURL;
        else navigate(`/${outletSlug}/confirmation/${response.appointment.id}`);
      })
      .catch((error: any) => {
        setIsRequestLoading(false);
        notify(
          `${t("errors:createAppointment.couldNotCreateAppointment")} ${
            error?.response?.data?.detail ?? ""
          }`,
          "error",
          {
            shouldPersist: true,
          }
        );
      });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box p={3}>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3} bgcolor="bg.blank">
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                {t("promptBookingConfirmation")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider variant="fullWidth" />

        <Box pt={2}>
          <DialogActions>
            <Grid container justifyContent="space-between" spacing={3} bgcolor="bg.paper">
              <Grid item xs={6}>
                <Button size="small" fullWidth variant="outlined" onClick={() => setOpen(false)}>
                  {t("common:cancel")}
                </Button>
              </Grid>

              <Grid item xs={6}>
                <LoadingButton
                  size="small"
                  loading={isRequestLoading}
                  fullWidth
                  onClick={handleAppointmentCreate}
                >
                  {t("common:confirm")}
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmBookingModal;
