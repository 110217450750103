import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "store";
import { selectCartDate, selectDate } from "store/selectors";
import { setDate } from "store/slices/organization/organizationSlice";

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

export type OutletBookingStateType = {
  selectedDate: DateTime;
  setSelectedDate: (date: DateTime) => void;

  selectedOutlet: Nullable<number>;
  setSelectedOutlet: Dispatch<SetStateAction<Nullable<number>>>;

  selectedStaff: Nullable<number>;
  setSelectedStaff: Dispatch<SetStateAction<Nullable<number>>>;

  selectedPurchasable: Nullable<number>;
  setSelectedPurchasable: Dispatch<SetStateAction<Nullable<number>>>;

  selectedPurchasableNumberOfPeople: Nullable<number>;
  setSelectedPurchasableNumberOfPeople: Dispatch<SetStateAction<Nullable<number>>>;

  selectedPurchasablePackage: Nullable<number>;
  setSelectedPurchasablePackage: Dispatch<SetStateAction<Nullable<number>>>;
};

const initialState = {
  selectedDate: DateTime.now().startOf("day"),
  setSelectedDate: unimplementedFunction,

  selectedOutlet: null,
  setSelectedOutlet: unimplementedFunction,

  selectedStaff: null,
  setSelectedStaff: unimplementedFunction,

  selectedPurchasable: null,
  setSelectedPurchasable: unimplementedFunction,

  selectedPurchasableNumberOfPeople: null,
  setSelectedPurchasableNumberOfPeople: unimplementedFunction,

  selectedPurchasablePackage: null,
  setSelectedPurchasablePackage: unimplementedFunction,
};

const OutletBookingContext = createContext<OutletBookingStateType>(initialState);

export default OutletBookingContext;

function OutletBookingContextProvider({ children }: { children: ReactNode }) {
  const today = DateTime.now().startOf("day");
  const hasSetDate = useRef(false);
  const outlet = useParamOutlet();
  const storeDate = useSelector(selectDate);

  const dispatch = useDispatch();

  const [selectedOutlet, setSelectedOutlet] = useState<Nullable<number>>(outlet?.id || null);

  const [selectedStaff, setSelectedStaff] = useState<Nullable<number>>(null);

  const [selectedPurchasable, setSelectedPurchasable] = useState<Nullable<number>>(null);

  const [selectedPurchasablePackage, setSelectedPurchasablePackage] =
    useState<Nullable<number>>(null);

  const [selectedPurchasableNumberOfPeople, setSelectedPurchasableNumberOfPeople] =
    useState<Nullable<number>>(null);

  const cartDate = useSelector(selectCartDate);

  // Effect to Select the date on the cartDate on page load
  useEffect(() => {
    if (!hasSetDate.current) {
      if (cartDate) {
        const isCartDateHasPassed = today.startOf("day") > cartDate.startOf("day");

        if (isCartDateHasPassed) {
          dispatch(setDate({ date: DateTime.now().startOf("day").toISO() }));
        } else {
          dispatch(setDate({ date: cartDate.startOf("day").toISO() }));
        }

        hasSetDate.current = true;
      }
    }
  }, [cartDate, dispatch, today]);

  return (
    <OutletBookingContext.Provider
      value={{
        selectedDate: storeDate,
        setSelectedDate: (date: DateTime) =>
          dispatch(setDate({ date: date.startOf("day").toISO() })),

        selectedOutlet,
        setSelectedOutlet,

        selectedStaff,
        setSelectedStaff,

        selectedPurchasable,
        setSelectedPurchasable,

        selectedPurchasablePackage,
        setSelectedPurchasablePackage,

        selectedPurchasableNumberOfPeople,
        setSelectedPurchasableNumberOfPeople,
      }}
    >
      {children}
    </OutletBookingContext.Provider>
  );
}

export { OutletBookingContextProvider };
