import { Box, Grid, Typography } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectAllServiceVariants,
  selectCart,
  selectOrganization,
  selectOutletBySlug,
  selectPackagesByCategoryIdAndOutletId,
  selectServicesByCategoryIdAndOutletIdAndEmployeeId,
  selectServicesByOutletId,
} from "store/selectors";

import { TYPES } from "./constants";
import PurchasableCard from "./PurchasableCard";
import PurchasedArticleCard from "./PurchasableCard/PurchasedArticleCard";
import { checkIsHomeServiceOrganization } from "utils/organization";

type CategoryServicesProps = {
  category?: ServiceCategory | PackageCategory;
  type: string;
};

export default function CategoryServices({ category, type }: CategoryServicesProps) {
  const { t } = useTranslation("outletPage");

  const paramOutlet = useParamOutlet(true);
  const { employeeId: rowEmployeeId } = useParams();

  const outletSlug = paramOutlet?.slug || "";

  const employeeId = rowEmployeeId ? Number(rowEmployeeId) : undefined;

  const cart = useSelector(selectCart);

  const outlet = useSelector(selectOutletBySlug(outletSlug));
  const categoryServices = useSelector(
    selectServicesByCategoryIdAndOutletIdAndEmployeeId(
      category?.id ?? 0,
      outlet?.id ?? 0,
      employeeId
    )
  );
  const allServices = useSelector(selectServicesByOutletId(outlet?.id ?? 0));

  const allServiceVariants = useSelector(selectAllServiceVariants);

  const displayedServices = category ? categoryServices : allServices;

  const serviceVariantsInCart = cart.articles.map((article) =>
    allServiceVariants.find((serviceVariant) => serviceVariant.id === article.serviceVariant)
  );

  const servicesInCart = serviceVariantsInCart.map((serviceVariant) => serviceVariant?.service);

  const packages = useSelector(
    selectPackagesByCategoryIdAndOutletId(category?.id ?? 0, outlet?.id ?? 0)
  );

  const org = useSelector(selectOrganization);
  const isHomeService = checkIsHomeServiceOrganization(org?.industry!);

  const servicesList = () => {
    if (employeeId && !categoryServices.length)
      return (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" p={3}>
            <Typography variant="primary" color="#848FA1" align="center">
              {t("staffHasNoServicesInCategory")}
            </Typography>
          </Box>
        </Grid>
      );

    return (
      <>
        {isHomeService && (
          <>
            {serviceVariantsInCart.map((serviceVariant) => (
              <Grid key={serviceVariant?.id ?? 0} item xs={12}>
                <PurchasedArticleCard
                  serviceId={serviceVariant?.service}
                  variantId={serviceVariant?.id}
                />
              </Grid>
            ))}
          </>
        )}
        {displayedServices
          .sort((a, b) => a.position - b.position)
          .filter((service) => !isHomeService || !servicesInCart.includes(service.id))
          .map((service) => (
            <Grid key={service?.id ?? 0} item xs={12}>
              <PurchasableCard serviceId={service.id} />
            </Grid>
          ))}
      </>
    );
  };

  const packagesList = packages.map((pkg) => (
    <Grid key={pkg?.id ?? 0} item xs={12}>
      <PurchasableCard packageId={pkg.id} />
    </Grid>
  ));

  return (
    <Grid container spacing={1}>
      {type === TYPES.SERVICES ? servicesList() : packagesList}
    </Grid>
  );
}
