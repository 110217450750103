import { NotificationAlertContext, NotificationProps } from "providers/notification";
import { useContext } from "react";

function useNotify() {
  const { setNotificationProps } = useContext(NotificationAlertContext);

  const notify = (
    message: NotificationProps["message"],
    severity: NotificationProps["severity"]
  ) => {
    setNotificationProps({ message, severity, alertDuration: 5000, isAlertVisible: true });
  };

  return notify;
}
export default useNotify;
