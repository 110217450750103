import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "store/utils/createThunkFromApi";
import { ProfileAddress } from "types/ProfileAddress";

import { SliceStatus } from "../utils";
import api from "./profileAddressesApi";

export const profileAddressAdapter = createEntityAdapter<ProfileAddress>();

const initialState = profileAddressAdapter.getInitialState({ status: SliceStatus.IDLE });

export const getProfileAddresses = createThunkFromApi(
  "profileAddresses/getProfileAddress",
  api.getProfileAddresses
);

export const updateProfileAddress = createThunkFromApi(
  "profileAddresses/updateProfileAddress",
  api.updateProfileAddress
);

export const createProfileAddress = createThunkFromApi(
  "profileAddresses/createProfileAddress",
  api.createProfileAddress
);

export const deleteProfileAddress = createThunkFromApi(
  "profileAddresses/deleteProfileAddress",
  api.deleteProfileAddress
);

const profileAddressesSlice = createSlice({
  name: "profileAddresses",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getProfileAddresses.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getProfileAddresses.fulfilled, (state, { payload }) => {
        profileAddressAdapter.setAll(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getProfileAddresses.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(createProfileAddress.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createProfileAddress.fulfilled, (state, { payload }) => {
        profileAddressAdapter.addOne(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(createProfileAddress.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(updateProfileAddress.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(updateProfileAddress.fulfilled, (state, { payload }) => {
        profileAddressAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(updateProfileAddress.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(deleteProfileAddress.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(deleteProfileAddress.fulfilled, (state, { payload }) => {
        profileAddressAdapter.removeOne(state, payload.id);
        state.status = SliceStatus.IDLE;
      })
      .addCase(deleteProfileAddress.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default profileAddressesSlice.reducer;
