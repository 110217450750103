import http from "utils/http";

type CreateDraftArticleArguments = {
  peopleCount: number;
  serviceVariant?: number;
  packageVariant?: number;
  date: string;
  area: number;
  outlet: number;
} & (
    | {
      start: string;
    }
    | {
      sessionId: string;
    }
  );

type CreateDraftArticleResponse = {
  id: number;
  sessionId: string;
  start: string;
  end: string;
  createdAt: string;
  updatedAt: string;
  outlet: number;
  serviceVariant: number;
  flexService: number;
  staff: number;
  resource: Nullable<number>;
};

const cartApi = {
  createDraftArticle: async (data: CreateDraftArticleArguments) =>
    http.post<CreateDraftArticleResponse[]>("mp/draft_appointments/ob/drafts/", data),
  deleteDraftArticle: async (id: number) => http.delete(`mp/draft_appointments/ob/drafts/${id}/`),
  deleteDraftArticles: async (ids: number[]) => http.delete(`mp/draft_appointments/ob/drafts/clear-drafts/?draft_ids=${ids.join(",")}`),
  clearSessionDrafts: async (sessionId: string) =>
    http.delete(`/mp/draft_appointments/ob/drafts/clear-drafts/?session_id=${sessionId}`),
};

export default cartApi;
