import { IconButton, Radio, Stack, Typography } from "@mui/material";
import { DeleteIcon } from "assets/icons/DeleteIcon";
import { EditIcon } from "assets/icons/EditIcon";
import useAddressLabel from "hooks/useAddressLabel";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { deleteProfileAddress } from "store/slices/profileAddresses/profileAddressesSlice";
import { ProfileAddress } from "types/ProfileAddress";
import useNotify from "utils/notifications/useNotify";

import ClientAddressForm from "./ClientAddressForm";

type ClientAddressSectionProps = {
  address: ProfileAddress;

  areaStatusChip?: React.ReactNode;

  hasRadioButton?: boolean;
  handleToggleSelection?: () => void;

  handleSaveForm?: (address: ProfileAddress) => void;
  handleCancelEditForm?: () => void;

  isSelected?: boolean; // for Radio button status
  isDisabled?: boolean;
  shouldShowManageButtons?: boolean;

  openEditForm?: (addressId: number) => void;
  closeEditForm?: () => void;

  isEditFormOpen?: boolean;
};

const ClientAddressSection = ({
  address,
  areaStatusChip,

  hasRadioButton,
  isSelected,
  handleToggleSelection,

  handleSaveForm,
  handleCancelEditForm,
  openEditForm,
  closeEditForm,
  isEditFormOpen,

  isDisabled,
  shouldShowManageButtons,
}: ClientAddressSectionProps) => {
  const { t } = useTranslation("client");
  const dispatch = useDispatch();
  const notify = useNotify();

  const addressLabel = useAddressLabel(address);

  const handleEditClick = () => {
    if (isDisabled || !address.id || !openEditForm) return;

    openEditForm(address.id);
  };

  const handleDeleteClick = () => {
    if (isDisabled || !address.id) return;

    dispatch(deleteProfileAddress(address))
      .unwrap()
      .then(() => {
        notify(
          t("entitySuccessfullyDeleted", {
            entity: t("client:address"),
            ns: "common",
          }),
          "success"
        );
      })
      .catch(() => {
        notify(
          t("entityFailedToDelete", {
            entity: t("client:address"),
            ns: "common",
          }),
          "error"
        );
      });
  };

  if (isEditFormOpen && closeEditForm)
    return (
      <ClientAddressForm
        address={address}
        handleSaveForm={handleSaveForm}
        handleCancelEditForm={handleCancelEditForm}
        closeForm={closeEditForm}
      />
    );

  return (
    <Stack
      borderRadius={1}
      bgcolor="bg.secondary"
      p={3}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{
        opacity: isDisabled ? 0.5 : 1,
        cursor: getCursorStyle(!!isDisabled, !!handleToggleSelection),
        // disable selecting text
        userSelect: "none",
      }}
      onClick={handleToggleSelection && !isDisabled ? handleToggleSelection : undefined}
    >
      <Stack direction="row" gap={2}>
        {hasRadioButton && (
          <Radio
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            checked={isSelected}
            value={address.id}
            disabled={isDisabled}
            onClick={handleToggleSelection ? handleToggleSelection : undefined}
          />
        )}

        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="primary" fontWeight={700}>
              {address.areaName || ""}
            </Typography>

            {areaStatusChip}
          </Stack>

          <Typography variant="secondary" color="text.label" fontWeight={500}>
            {addressLabel}
          </Typography>
        </Stack>
      </Stack>

      {shouldShowManageButtons && (
        <Stack direction="row" spacing={1}>
          <IconButton onClick={handleEditClick} disabled={isDisabled || !address.id}>
            <EditIcon
              fontSize="small"
              sx={{
                color: "#6C798F",
              }}
            />
          </IconButton>

          <IconButton onClick={handleDeleteClick} disabled={isDisabled || !address.id}>
            <DeleteIcon
              fontSize="small"
              sx={{
                color: "#6C798F",
              }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default ClientAddressSection;

const getCursorStyle = (isDisabled: boolean, isSelectable: boolean) => {
  if (isDisabled) return "not-allowed";

  if (isSelectable) return "pointer";

  return "unset";
};
