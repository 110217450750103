import { Calendar, Clock } from "@bookpeep/ui";
import { Grid } from "@mui/material";
import { LocationIcon } from "assets/icons/Location";
import { UserIcon } from "assets/icons/User";
import { AreaSelectorModal } from "components/AreaSelectorModal";
import HomeServiceConfirmUpdateAppointmentModal from "components/HomeServiceConfirmUpdateAppointmentModal";
import HomeServiceResetAppointmentModal from "components/HomeServiceResetAppointmentModal";
import { NumberOfPeopleModal } from "components/NumberOfPeopleModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectCart, selectHomeServiceArea, selectHomeServiceNumOfPeople } from "store/selectors";
import { selectAllAreas } from "store/slices/areas/areasSelectors";
import { setArea, setCartAddress, setNumOfPeople } from "store/slices/cart/cartSlice";
import getEarliestCartArticleSlot from "store/slices/cart/getEarliestCartArticleSlot";
import { selectAllCities } from "store/slices/cities/citySelectors";
import { selectAllZones } from "store/slices/zones/zoneSelectors";

import getSlotPickerDateLabel from "./getSlotPickerDateLabel";
import HomeServiceAppointmentDetailsItem from "./HomeServiceAppointmentDetailsItem";

export default function HomeServiceAppointmentDetails() {
  const { t } = useTranslation();

  const cart = useSelector(selectCart);

  const dispatch = useDispatch();
  const areas = useSelector(selectAllAreas);
  const zones = useSelector(selectAllZones);
  const cities = useSelector(selectAllCities);
  const selectedArea = useSelector(selectHomeServiceArea);
  const selectedNumberOfPeople = useSelector(selectHomeServiceNumOfPeople);

  const [editedField, setEditedField] = useState<null | string>(null);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [isNumberOfPeopleModalOpen, setIsNumberOfPeopleModalOpen] = useState(false);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const handleChangeArea = () => {
    if (cart.articles.length !== 0) {
      setEditedField("area");
    } else {
      setIsAreaModalOpen(true);
    }
  };

  const handleChangeDate = () => {
    setIsDeleteConfirmationModalOpen(true);
  };
  const handleChangeTime = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleChangeNumberOfPeople = () => {
    if (cart.articles.length !== 0) {
      setEditedField("numberOfPeople");
    } else {
      setIsNumberOfPeopleModalOpen(true);
    }
  };

  if (!selectedArea || !selectedNumberOfPeople) {
    return null;
  }

  const earliestStartTime = getEarliestCartArticleSlot(cart.articles);

  return (
    <>
      <Grid container justifyContent="space-evenly" p={1} rowGap={1}>
        <HomeServiceAppointmentDetailsItem
          onClick={handleChangeArea}
          icon={<LocationIcon style={{ color: "#939BAA" }} fontSize="small" />}
          title={areas.find((area) => area.id === selectedArea)?.name!}
        />
        <HomeServiceAppointmentDetailsItem
          onClick={handleChangeNumberOfPeople}
          icon={<UserIcon style={{ color: "#939BAA" }} fontSize="small" />}
          title={selectedNumberOfPeople.toString()}
        />
        {earliestStartTime && (
          <>
            <HomeServiceAppointmentDetailsItem
              onClick={handleChangeDate}
              icon={<Calendar style={{ color: "#939BAA" }} fontSize="small" />}
              title={t(getSlotPickerDateLabel(earliestStartTime?.toISO())[0])}
            />
            <HomeServiceAppointmentDetailsItem
              onClick={handleChangeTime}
              icon={<Clock style={{ color: "#939BAA" }} fontSize="small" />}
              title={earliestStartTime.toFormat("hh:mm a")}
            />
          </>
        )}
      </Grid>
      <HomeServiceConfirmUpdateAppointmentModal
        open={editedField !== null}
        onConfirm={() => {
          if (editedField === "area") {
            setIsAreaModalOpen(true);
            setEditedField(null);
          } else if (editedField === "numberOfPeople") {
            setIsNumberOfPeopleModalOpen(true);
            setEditedField(null);
          }
        }}
        onClose={() => setEditedField(null)}
      />

      {isAreaModalOpen && (
        <AreaSelectorModal
          selectedAreaId={selectedArea}
          zones={zones}
          areas={areas}
          cities={cities}
          onSelect={(area) => {
            dispatch(setArea(area));
            dispatch(setCartAddress(null));
            setIsAreaModalOpen(false);
            setIsNumberOfPeopleModalOpen(true);
          }}
          onClose={() => setIsAreaModalOpen(false)}
        />
      )}

      {isNumberOfPeopleModalOpen && (
        <NumberOfPeopleModal
          numberOfPeople={selectedNumberOfPeople!}
          onSelect={(numberOfPeople) => {
            dispatch(setNumOfPeople(numberOfPeople));
            setIsNumberOfPeopleModalOpen(false);
          }}
          onClose={() => setIsNumberOfPeopleModalOpen(false)}
        />
      )}
      {isDeleteConfirmationModalOpen && (
        <HomeServiceResetAppointmentModal
          onClose={() => {
            setIsDeleteConfirmationModalOpen(false);
          }}
          onConfirm={() => {
            setIsDeleteConfirmationModalOpen(false);
          }}
          open={isDeleteConfirmationModalOpen}
        />
      )}
    </>
  );
}
