import { Box, Button } from "@mui/material";
import { UserIcon } from "assets/icons/User";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DrawerModal from "./DrawerModal";
import ModalFooterButtons from "./ClientAddressesModal/ModalFooterButtons";

type NumberOfPeopleModalProps = {
  numberOfPeople: number;
  onSelect: (numberOfPeople: number) => void;
  onClose: () => void;
};

export function NumberOfPeopleModal({
  numberOfPeople,
  onClose,
  onSelect,
}: NumberOfPeopleModalProps) {
  const { t } = useTranslation(["outletPage", "common"]);

  const [numOfPeople, setNumOfPeople] = useState<Nullable<number>>(numberOfPeople);

  return (
    <DrawerModal
      title={t("howManyPeopleInThisAppointment")}
      open
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "20vh", padding: "16px" } }}
    >
      <Box mt={1}>
        <NumberOfPeopleButtons
          maxNumberOfPeople={4}
          numOfPeople={numOfPeople}
          setNumberOfPeople={setNumOfPeople}
        />
      </Box>

      <ModalFooterButtons
        handleContinueClick={() => {
          if (numOfPeople !== null) {
            onSelect(numOfPeople);
          }
        }}
        handleCancelClick={onClose}
        continueButtonProps={{
          disabled: numOfPeople === null,
        }}
      />
    </DrawerModal>
  );
}

export function NumberOfPeopleButtons({
  maxNumberOfPeople,
  numOfPeople,
  setNumberOfPeople,
}: {
  maxNumberOfPeople: number;
  numOfPeople: number | null;
  setNumberOfPeople: (num: number) => void;
}) {
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {Array.from({ length: maxNumberOfPeople })
        .map((_, i) => i + 1)
        .map((num) => (
          <Button
            style={{
              width: "100%",
              minHeight: "60px",
            }}
            key={num}
            variant={numOfPeople === num ? "contained" : "outlined"}
            onClick={() => {
              setNumberOfPeople(num);
            }}
          >
            <Box display="flex" flexDirection="column" m={2} gap={1}>
              <Box display="flex" flexDirection="row">
                {Array.from({ length: num }).map((_, i) => (
                  <UserIcon fontSize="small" key={i} />
                ))}
              </Box>
              {num}
            </Box>
          </Button>
        ))}
    </Box>
  );
}
