import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import sortArticlesAscending from "helpers/article/sortArticlesAscending";
import { DateTime } from "luxon";
import matchSlice from "store/utils/matchSlice";
import cart, { generateEmptyCart } from "utils/cart";

import { logout } from "../auth/authSlice";
import getEarliestCartArticleSlot from "./getEarliestCartArticleSlot";

export type CartSliceState = {
  outlet: Nullable<number>;
  sessionId: Nullable<string>;

  notes: string;

  articles: CartArticle[];

  // Home Service fields
  area: Nullable<number>;
  numOfPeople: Nullable<number>;
  address: Nullable<number>;

  activeArticleId: Nullable<number>;
};

const initialState: CartSliceState = {
  outlet: null,
  sessionId: null,

  notes: "",

  articles: [],
  area: null,
  numOfPeople: null,
  address: null,
  activeArticleId: null,
};

const resetCartState = () => {
  cart.destroyAllCarts();

  return initialState;
};

const cartSlice = createSlice({
  name: "cart",
  initialState,

  reducers: {
    updateCartNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },

    addArticleToCart: (state, action: PayloadAction<CartArticle>) => {
      return { ...state, articles: sortArticlesAscending([...state.articles, action.payload]) };
    },

    removeArticleFromCart: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        articles: sortArticlesAscending(state.articles).filter(
          (_, index) => index !== action.payload
        ) as CartArticle[], // Avoids Immer type inference
      };
    },

    updateArticleInCart: (
      state,
      action: PayloadAction<{ index: number; article: CartArticle }>
    ) => {
      return {
        ...state,
        articles: sortArticlesAscending(
          state.articles.map((article, index) => {
            if (index === action.payload.index) return action.payload.article;
            return article;
          }) as CartArticle[]
        ),
      };
    },

    updateArticleSubscriptionPurchase: (
      state,
      action: PayloadAction<{ subscriptionPurchaseId: Nullable<number>; id: number }>
    ) => {
      return {
        ...state,
        articles: sortArticlesAscending(
          state.articles.map((article, index) => {
            if (index === action.payload.id) {
              return { ...article, subscriptionPurchaseId: action.payload.subscriptionPurchaseId };
            }
            return article;
          }) as CartArticle[]
        ), // Avoids Immer type inference
      };
    },

    clearCart: resetCartState,

    clearCartArticles: (state) => {
      return {
        ...state,
        articles: [],
        sessionId: null,
      };
    },

    // TODO: The following rules need to be added to the switchCartOutlet action:
    //        - Clear the cart if:
    //          - If any article-slot's serviceVariantId is not available in the serviceVariants slice
    //          - If any package article's variant id is not available in the packageVariants slice
    //        - Update total price of article if it doesn't match the actual article item price (service or package variant)
    switchCartOutlet: (_, { payload: outletId }: PayloadAction<Nullable<number>>) => {
      if (!outletId) return initialState;

      const storedCart = cart.getOutletCart(outletId);

      if (!storedCart) return generateEmptyCart(outletId);

      const earliestSlot = getEarliestCartArticleSlot(storedCart.articles);
      const earliestAvailableTime = DateTime.now().plus({ minutes: 15 });

      // Clear cart if the earliest slot is before the earliest available time
      if (earliestSlot && earliestSlot < earliestAvailableTime) return generateEmptyCart(outletId);

      return storedCart;
    },

    setArea: (state, action: PayloadAction<number>) => {
      state.area = action.payload;
    },

    setNumOfPeople: (state, action: PayloadAction<number>) => {
      state.numOfPeople = action.payload;
    },

    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },

    setCartAddress: (state, action: PayloadAction<Nullable<number>>) => {
      state.address = action.payload;
    },
  },

  extraReducers(reducers) {
    reducers
      .addMatcher(matchSlice("cart"), (state) => {
        if (state.outlet) cart.setOutletCart(state.outlet, state);
      })
      .addMatcher(logout.match, resetCartState);
  },
});

export default cartSlice.reducer;

export const {
  addArticleToCart,
  removeArticleFromCart,
  updateCartNotes,
  switchCartOutlet,
  clearCart,
  clearCartArticles,
  updateArticleInCart,
  setArea,
  setCartAddress,
  setNumOfPeople,
  updateArticleSubscriptionPurchase,
  setSessionId,
} = cartSlice.actions;
