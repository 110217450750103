import { Box, Divider, Typography } from "@mui/material";
import ExpanderButton from "components/ExpanderButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPackageVariantsByPackageId } from "store/selectors";

import PackageService from "../PackageService";

type PurchasableCardPackageDetailsProps = {
  packageId: number;
  serviceId: number;
  packageDescription: string;
  serviceDescription: string;
};

const packageColor = "#B447E6";
const packageBackground = "#F6E3FF";

const serviceColor = "#4048D6";
const serviceBackground = "#F3F3FB";

const secondaryTextColor = "#0A1F44";

const PurchasableCardPackageDetails = ({
  packageId,
  serviceId,
  packageDescription,
  serviceDescription,
}: PurchasableCardPackageDetailsProps) => {
  const { t } = useTranslation("outletPage");

  const [shouldShowServices, setShouldShowServices] = useState(false);

  const variants = useSelector(selectPackageVariantsByPackageId(packageId || 0));

  const isPackage = !!packageId;
  const isService = !!serviceId;

  const backgroundColor = isPackage ? packageBackground : serviceBackground;

  const expandableTextColor = isPackage ? packageColor : serviceColor;

  if (isService && !serviceDescription) {
    return null;
  }

  const descriptionText = isPackage ? packageDescription : serviceDescription;

  return (
    <Box width="100%">
      <ExpanderButton
        disableElevation
        disableRipple
        variant="contained"
        bgcolor={backgroundColor}
        fullWidth
        expanded={shouldShowServices}
        onClick={(e) => {
          e.stopPropagation();
          setShouldShowServices(!shouldShowServices);
        }}
        iconColor={expandableTextColor}
      >
        <Box display="flex">
          <Typography variant="secondary" fontWeight={500} color={expandableTextColor}>
            {isPackage
              ? shouldShowServices
                ? t("hidePackageDetails")
                : t("showPackageDetails")
              : shouldShowServices
              ? t("hideServiceDescriptions")
              : t("showServiceDescriptions")}
          </Typography>
        </Box>
      </ExpanderButton>

      <Box
        display={shouldShowServices ? "block" : "none"}
        sx={{ borderRadius: "0 0 8px 8px" }}
        bgcolor={backgroundColor}
      >
        {isPackage &&
          variants.length &&
          variants[0].serviceVariants.map((variant, index) => (
            <PackageService
              key={variant}
              color={packageColor}
              secondaryColor={secondaryTextColor}
              variantId={variant}
              displayDivider={index !== variants[0].serviceVariants.length - 1}
            />
          ))}

        {descriptionText && (
          <>
            <Box display="flex" justifyContent="center">
              <Divider
                sx={{ color: `${packageColor}`, opacity: "50%", width: "95%" }}
                variant="fullWidth"
              />
            </Box>

            <Box p={2}>
              <Typography variant="secondary" sx={{ color: secondaryTextColor }} fontWeight={500}>
                {descriptionText}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PurchasableCardPackageDetails;
