import { createSvgIcon } from "@mui/material";

export const EditIcon = createSvgIcon(
  // 🆘 NOTE: You need to pass color to sx prop to change the color of the icon for the icon to be visible
  <svg width="24" height="24" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.68507 3.2809C2.64577 3.2809 2.61391 3.31276 2.61391 3.35206V13.3146C2.61391 13.3539 2.64577 13.3858 2.68507 13.3858H12.6476C12.6869 13.3858 12.7188 13.3539 12.7188 13.3146V9.75655C12.7188 9.40284 13.0055 9.1161 13.3592 9.1161C13.7129 9.1161 13.9997 9.40284 13.9997 9.75655V13.3146C13.9997 14.0613 13.3943 14.6667 12.6476 14.6667H2.68507C1.93835 14.6667 1.33301 14.0613 1.33301 13.3146V3.35206C1.33301 2.60534 1.93835 2 2.68507 2H6.24312C6.59683 2 6.88357 2.28674 6.88357 2.64045C6.88357 2.99416 6.59683 3.2809 6.24312 3.2809H2.68507Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1261 0.857521C12.8884 0.602831 12.5029 0.602831 12.2652 0.857521L6.17828 7.37926C6.06413 7.50157 6 7.66745 6 7.84042V10.0143C6 10.3745 6.27252 10.6665 6.6087 10.6665H8.63768C8.79912 10.6665 8.95394 10.5978 9.06809 10.4755L15.155 3.95375C15.2692 3.83144 15.3333 3.66556 15.3333 3.49259C15.3333 3.31962 15.2692 3.15374 15.155 3.03143L13.1261 0.857521ZM7.21739 9.36216V8.11056L10.7622 4.31257L11.9706 5.52101L8.38555 9.36216H7.21739ZM12.824 4.60665L13.8638 3.49259L12.6957 2.24099L11.6156 3.39821L12.824 4.60665Z"
      fill="currentColor"
    />
  </svg>,
  "EditIcon"
);
