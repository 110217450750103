import { RootState } from "store";

import { SliceStatus } from "../utils";
import { areasAdapter } from "./areasSlice";

const selectAreasState = (state: RootState) => state.areas;

const { selectById, selectAll } = areasAdapter.getSelectors(selectAreasState);

export const selectAreaById = (id: Nullable<number | string>) => (state: RootState) =>
  selectById(state, id || "");

export const selectAllAreas = selectAll;

export const selectAreAreasLoading = (state: RootState) =>
  selectAreasState(state).status === SliceStatus.LOADING;
