import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Stack, TextField } from "@mui/material";
import AreaAutocomplete from "components/AreaAutocomplete";
import useFormValidation from "hooks/useFormValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import {
  createProfileAddress,
  updateProfileAddress,
} from "store/slices/profileAddresses/profileAddressesSlice";
import { ProfileAddress } from "types/ProfileAddress";
import useNotify from "utils/notifications/useNotify";

import ModalFooterButtons from "./ModalFooterButtons";
import { ProfileAddressFormFields, profileAddressFormSchema } from "./profileAddressFormSchema";

type ClientAddressFormProps = {
  address?: ProfileAddress;
  selectedAreaId?: Nullable<number>;

  handleSaveForm?: (address: ProfileAddress) => void;
  handleCancelEditForm?: () => void;
  closeForm: () => void;
};

const ClientAddressForm = ({
  address,
  selectedAreaId,
  handleSaveForm,
  handleCancelEditForm,
  closeForm,
}: ClientAddressFormProps) => {
  const { t } = useTranslation(["common"]);
  const notify = useNotify();
  const dispatch = useDispatch();

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { register, formState, reset, handleSubmit, watch, setValue } =
    useForm<ProfileAddressFormFields>({
      resolver: zodResolver(profileAddressFormSchema),
      defaultValues: {
        ...address,
        area: selectedAreaId || address?.area,
      },
    });

  const isEditMode = !!address;

  const registerValidation = useFormValidation(formState, { ns: "common" });

  const handleCloseForm = () => {
    closeForm();
    reset();
  };

  const handleCancelClick = () => {
    handleCloseForm();
    handleCancelEditForm && handleCancelEditForm();
  };

  const handleSaveClick = handleSubmit((data: ProfileAddressFormFields) => {
    setIsRequestLoading(true);
    if (isEditMode) {
      dispatch(updateProfileAddress(data))
        .unwrap()
        .then((addressResponse) => {
          notify(
            t("common:entitySuccessfullyUpdated", {
              entity: t("address"),
            }),
            "success"
          );
          handleCloseForm();
          setIsRequestLoading(false);

          handleSaveForm && handleSaveForm({ ...addressResponse });
        })
        .catch((error) => {
          notify(
            t("common:entityFailedToBeUpdated", {
              entity: t("address"),
              error: error?.detail ?? "",
            }),
            "error"
          );
          setIsRequestLoading(false);
        });
    } else {
      dispatch(createProfileAddress(data))
        .unwrap()
        .then((addressResponse) => {
          notify(
            t("common:entitySuccessfullyCreated", {
              entity: t("address"),
            }),
            "success"
          );
          setIsRequestLoading(false);
          handleCloseForm();
          handleSaveForm && handleSaveForm({ ...addressResponse });
        })
        .catch((error) => {
          notify(
            t("common:failedToCreate", {
              entity: t("address"),
              error: error?.detail ?? "",
            }),
            "error"
          );
          setIsRequestLoading(false);
        });
    }
  });

  const handleChangeArea = (newValue: Nullable<number>) => {
    if (newValue) setValue("area", newValue);
    else reset({ area: undefined });
  };

  return (
    <Stack gap={1}>
      <form>
        <Grid container spacing={2} pt={3} px={2}>
          <Grid item xs={6}>
            <AreaAutocomplete
              label={t("requiredField", { fieldName: t("area") })}
              {...registerValidation("area", t("area"))}
              value={selectedAreaId || watch("area")}
              handleSelectValue={handleChangeArea}
              shouldApplyGroupByCity
              disabled={!!selectedAreaId}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t("requiredField", { fieldName: t("block") })}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("block") })}
              {...register("block")}
              {...registerValidation("block", t("block"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("requiredField", { fieldName: t("street") })}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("street") })}
              {...register("street")}
              {...registerValidation("street", t("street"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("avenue")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("avenue") })}
              {...register("avenue")}
              {...registerValidation("avenue", t("avenue"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("requiredField", { fieldName: t("buildingNameNumber") })}
              placeholder={t("fieldPlaceholder", { fieldName: t("buildingNameNumber") })}
              {...register("building")}
              {...registerValidation("building", t("buildingNameNumber"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t("flat")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("flat") })}
              {...register("flatNumber")}
              {...registerValidation("flatNumber", t("flat"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t("floor")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("floor") })}
              {...register("floor")}
              {...registerValidation("floor", t("floor"))}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>

      {isEditMode && (
        <Stack direction="row" spacing={2} pt={3} justifyContent="flex-end">
          <Button variant="text" onClick={handleCancelClick}>
            {t("cancel")}
          </Button>

          <LoadingButton color="primary" onClick={handleSaveClick} loading={isRequestLoading}>
            {t("save")}
          </LoadingButton>
        </Stack>
      )}

      <ModalFooterButtons
        handleContinueClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        continueButtonProps={{
          loading: isRequestLoading,
        }}
      />
    </Stack>
  );
};

export default ClientAddressForm;
