import { Box } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import useViewParam from "hooks/useViewParam";
import GoToCartButton from "pages/BookingPage/GoToCartButton";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectCart,
  selectHasFilledHomeServiceAppointmentDetails,
  selectOrganization,
  selectSubscriptionByOutletId,
} from "store/selectors";
import { trackEvent } from "tracking";
import { Subscription } from "types/Subscription";
import { checkIsHomeServiceOrganization } from "utils/organization";

import ChangeViewController, { OutletTabs } from "./ChangeViewController";
import EmployeeList from "./EmployeeList";
import OutletBookingContext from "./outletContext";
import PurchasableListTabs from "./PurchasableListTabs";
import SubscriptionList from "./Subscriptions/SubscriptionList";

const ServiceAndStaffListTabs = () => {
  const { t } = useTranslation("bookingPage");
  const { id: outletId } = useParamOutlet();

  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);
  const outletSubscriptions = useSelector(selectSubscriptionByOutletId(outletId));
  const cartHasArticles = !!cart.articles.length;
  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const filteredSubscriptions: Subscription[] = outletSubscriptions;

  const homeServicesTabs: OutletTabs[] = [OutletTabs.services, OutletTabs.subscriptions];

  const allTabs: OutletTabs[] = [OutletTabs.services, OutletTabs.staff, OutletTabs.subscriptions];

  const validTabs = isHomeService ? homeServicesTabs : allTabs;

  const validTabsObject = Object.fromEntries(validTabs.map((tab) => [tab, tab]));
  const [activeTab, setActiveTab] = useViewParam(validTabsObject, OutletTabs.services);

  const handleChangeView = (newTab: OutletTabs) => {
    setActiveTab(newTab);

    if (organization?.id && organization?.name) {
      trackEvent(
        "Flow Changed",
        { id: organization.id, name: organization.name },
        { flow: newTab }
      );
    }
  };
  const { selectedPurchasable } = useContext(OutletBookingContext);

  const hasFilledHomeServiceAppointmentDetails = useSelector(
    selectHasFilledHomeServiceAppointmentDetails
  );

  const isAddToCartVisible =
    isHomeService && (selectedPurchasable || !hasFilledHomeServiceAppointmentDetails);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minHeight="38vh"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" width="100%" rowGap={1} mt={1}>
        {!isHomeService && (
          <ChangeViewController
            currentView={activeTab}
            handleChangeView={handleChangeView}
            tabs={validTabs}
          />
        )}
        {activeTab === OutletTabs.services && <PurchasableListTabs />}
        {activeTab === OutletTabs.staff && <EmployeeList />}
        {activeTab === OutletTabs.subscriptions && (
          <SubscriptionList filteredSubscriptions={filteredSubscriptions} />
        )}
      </Box>

      {cartHasArticles && !isAddToCartVisible && (
        <Box
          display="flex"
          flexDirection="column"
          px={2}
          pt={2}
          bgcolor="white"
          width="100%"
          position="sticky"
          bottom={0}
          zIndex={200}
          borderTop="1px solid #E6E9EC"
        >
          <GoToCartButton prefix={t("checkout")} />
        </Box>
      )}
    </Box>
  );
};

export default memo(ServiceAndStaffListTabs);
