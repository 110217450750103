import { Close } from "@bookpeep/ui";
import {
  Grid,
  IconButton,
  PaperProps,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
  TypographyProps,
} from "@mui/material";

type DrawerModalProps = Optional<Omit<SwipeableDrawerProps, "title">, "onOpen"> & {
  PaperProps?: PaperProps;
  title: JSX.Element | string;
  titleProps?: TypographyProps;
  children: React.ReactNode;
  showClose?: boolean;
};

/**
 * MUI Drawer with rounded edges and anchor bottom
 */
export default function DrawerModal({
  anchor = "bottom",
  onOpen = () => {},
  PaperProps,
  title,
  titleProps,
  children,
  showClose = true,
  ...drawerProps
}: DrawerModalProps) {
  const { sx: paperSX, ...otherPaperProps } = PaperProps || {};

  return (
    <SwipeableDrawer
      onOpen={onOpen}
      anchor={anchor}
      title=""
      PaperProps={{
        sx: {
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          ...paperSX,
        },
        ...otherPaperProps,
      }}
      {...drawerProps}
    >
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h3" {...titleProps}>
            {title}
          </Typography>
        </Grid>

        {showClose && (
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <IconButton size="large" onClick={drawerProps.onClose}>
              <Close sx={{ width: "14px", height: "14px" }} />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {children}
    </SwipeableDrawer>
  );
}

/**
 * This component is stupid, it was only created because MUI v5 does
 * not support custom variants for MuiDrawer at this time.
 *
 * For more information look at these issues:
 *    - https://github.com/mui-org/material-ui/issues/22259
 *    - https://github.com/mui-org/material-ui/issues/21749
 *
 * Most likely won't be fixed for a long time.
 */
