import { AddPlus } from "@bookpeep/ui";
import { Alert, Button, Grid, TextField, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { BookingIcon } from "assets/icons/Booking";
import { InfoAlertIcon } from "assets/icons/InfoAlert";
import AppointmentAddressSection from "components/AppointmentAddressSection";
import AppointmentOrganizationHeader from "components/AppointmentOrganizationHeader";
import AuthenticationModal from "components/AuthenticationModal";
import CancellationPolicySection from "components/CancellationPolicySection";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import ConfirmBookingModal from "components/ConfirmBookingModal";
import PriceBox from "components/PriceBox";
import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectAuthIsLoggedIn, selectAuthUser, selectOrganization } from "store/selectors";
import { requestOtp } from "store/slices/auth/authSlice";
import {
  getDoArticlesHaveServiceWithPricingFrom,
  getUniqueCartArticlesWithDetails,
  selectCart,
} from "store/slices/cart/cartSelectors";
import { setCartAddress, updateCartNotes } from "store/slices/cart/cartSlice";
import { selectDownPaymentByAppointmentAmount } from "store/slices/onlinePaymentRules/onlinePaymentRulesSelectors";
import { trackEvent } from "tracking";
import { checkIsHomeServiceOrganization } from "utils/organization";

import CartArticles from "./CartArticles";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

export default function ReviewAppointmentPage() {
  const { t } = useTranslation(["reviewAppointmentPage", "common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(selectAuthIsLoggedIn);

  const cart = useSelector(selectCart);

  const uniqueArticlesWithDetails = useSelector(getUniqueCartArticlesWithDetails);

  const shouldShowTermsAndConditions = uniqueArticlesWithDetails.some(
    (article) => !!article?.termsAndConditions?.trim()
  );

  const doArticlesHaveFromPriceType = useSelector(getDoArticlesHaveServiceWithPricingFrom);

  const [open, setOpen] = useState(false);
  const outlet = useParamOutlet();

  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
    useState<boolean>(false);

  const outletSlug = outlet.slug || "";

  const dateToDisplay = cart.articles?.[0]?.slots?.[0]?.start;

  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const user = useSelector(selectAuthUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let totalServices = 0.0;
  let totalPrice = 0.0;

  cart.articles.forEach((article: CartArticle) => {
    totalServices += article.slots.length;
    if (!article.subscriptionPurchaseId) totalPrice += article.totalPrice;
  });

  const requiredDownPayment = useSelector(selectDownPaymentByAppointmentAmount(totalPrice));

  const handleAppointmentNotes = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(updateCartNotes(event.target.value));

  const handleCloseTermsAndConditionsModal = () => setIsTermsAndConditionsModalOpen(false);

  const handleNextStep = () => {
    const shouldShowAddressModal = isHomeService && !cart.address;

    if (shouldShowAddressModal) {
      if (!isLoggedIn) {
        navigate("/login");

        return;
      }

      setIsAddressModalOpen(true);
      return;
    }

    handleCloseTermsAndConditionsModal();

    if (organization?.id && organization?.name) {
      trackEvent(
        "Checkout Initiated",
        { id: organization.id, name: organization.name },
        { isLoggedIn: !!user, isVerified: !!user?.phoneVerified }
      );
    }

    if (user) {
      if (requiredDownPayment) navigate(`/${outletSlug}/checkout`);
      else setOpen(true);
      if (user.phoneVerified) {
        if (requiredDownPayment) navigate(`/${outletSlug}/checkout`);
        else setOpen(true);
      } else {
        dispatch(requestOtp({}));
        navigate("/verify-phone-number");
      }
    } else {
      setOpen(true);
    }
  };

  const handleChangeSelectedAddressId = (addressId: Nullable<number>) => {
    dispatch(setCartAddress(addressId));
  };

  if (!organization || !outlet) return <CentredSpinnerBox />;

  const timeToDisplay = dateToDisplay
    ? DateTime.fromISO(dateToDisplay).toLocaleString({
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "2-digit",
      })
    : null;

  return (
    <Box bgcolor="bg.secondary">
      {user ? (
        <ConfirmBookingModal open={open} setOpen={setOpen} />
      ) : (
        <AuthenticationModal open={open} setOpen={setOpen} />
      )}

      <Grid container justifyContent="center" bgcolor="#F6F7FC" spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            width="100%"
            height="140px"
            bgcolor="white"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            rowGap={1}
          >
            <BookingIcon sx={{ width: 61, height: 61 }} color="primary" />

            <Typography variant="h4" align="center">
              {t("reviewYourBooking")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {isHomeService && (
        <Box bgcolor="bg.blank" p={2} my={1}>
          <AppointmentAddressSection
            selectedAreaId={cart.area}
            selectedAddressId={cart.address}
            handleChangeSelectedAddressId={handleChangeSelectedAddressId}
            isAddressModalOpen={isAddressModalOpen}
            setIsAddressModalOpen={setIsAddressModalOpen}
          />
        </Box>
      )}

      <Grid container item xs={12} bgcolor="white" spacing={2} p={2} my={1}>
        <Grid item xs={12}>
          <AppointmentOrganizationHeader time={timeToDisplay} outlet={outlet} />
        </Grid>

        <Grid item xs={12} px={2}>
          <Typography variant="primary" fontWeight="bold" color="#848FA1">
            {t("yourItems")}
          </Typography>
        </Grid>

        <CartArticles />

        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddPlus />}
            fullWidth
            onClick={() => {
              if (organization?.id && organization?.name) {
                trackEvent(
                  "Add more items initiated",
                  { id: organization.id, name: organization.name },
                  {}
                );
              }

              navigate(`/${outletSlug}`);
            }}
          >
            {t("addMoreServices")}
          </Button>
        </Grid>
      </Grid>

      <Box display="flex" width="100%" p={2} flexDirection="column" bgcolor="white" rowGap={2}>
        <Typography variant="primary" fontWeight="bold" color="#848FA1">
          {t("bookingNotes")}
        </Typography>

        <TextField
          fullWidth
          multiline
          maxRows={3}
          placeholder={t("addBookingNotes")}
          value={cart.notes}
          onChange={handleAppointmentNotes}
        />
      </Box>

      <CancellationPolicySection />

      {shouldShowTermsAndConditions && (
        <TermsAndConditionsModal
          open={isTermsAndConditionsModalOpen}
          onClose={handleCloseTermsAndConditionsModal}
          handleNextStep={handleNextStep}
        />
      )}

      <Grid mt={2} container justifyContent="center" bgcolor="white" p={2} spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {t("common:totalPrice")}
              {" • "}
              <Typography variant="primary">
                {t("glossary:serviceCount", { count: totalServices })}
              </Typography>
            </Typography>

            <PriceBox
              value={totalPrice}
              variant="h4"
              textSx={{ fontWeight: 500, color: "primary.main" }}
            />
          </Box>
        </Grid>

        {requiredDownPayment === 0 && !!cart.articles.length && (
          <Grid item xs={12}>
            <Alert severity="success" variant="filled">
              {t("noPaymentNeeded", { organization: organization.name })}
            </Alert>
          </Grid>
        )}

        {doArticlesHaveFromPriceType && (
          <Grid item xs={12}>
            <StyledAlert variant="filled" icon={<InfoAlertIcon viewBox="0 0 20 20" />}>
              {t("priceMightChange")}
            </StyledAlert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={
              shouldShowTermsAndConditions
                ? () => setIsTermsAndConditionsModalOpen(true)
                : handleNextStep
            }
            disabled={!cart.articles.length}
            size="medium"
          >
            {requiredDownPayment ? t("checkout") : t("common:confirmBooking")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  background: "#FFF4E3",
  color: "#0A1F44",
  borderRadius: theme.spacing(2),
  fontWeight: 500,

  "& .MuiAlert-icon": {
    alignItems: "center",
  },
}));
