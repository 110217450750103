import { Skeleton, Stack, Typography } from "@mui/material";

const ClientAddressSectionSkeleton = () => {
  return (
    <Stack
      borderRadius={1}
      bgcolor="bg.secondary"
      p={3}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Stack gap={1}>
        <Typography variant="primary" fontWeight={700}>
          <Skeleton width={60} />
        </Typography>

        <Typography variant="secondary" color="text.label" fontWeight={500}>
          <Skeleton width={60} />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ClientAddressSectionSkeleton;
