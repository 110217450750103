/**
 * This file contains all the type guards for the primitive types
 */

/**
 * This function is used to filter out undefined values from an array, it can be used within a Array.filter function
 * @param argument
 * @returns
 */

export function isDefined<T>(element: T | undefined): element is T {
  return element !== undefined;
}
