import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SliceStatus } from "store/slices/utils";
import createThunkFromApi from "store/utils/createThunkFromApi";

import api from "./cityApi";

export const citiesAdapter = createEntityAdapter<City>({
  selectId: (city) => city.id,
});

export const getCities = createThunkFromApi("cities/getCities", api.getCities);

const initialState = citiesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const citySlice = createSlice({
  name: "cities",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getCities.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        citiesAdapter.setAll(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getCities.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default citySlice.reducer;
