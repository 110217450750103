import { createSvgIcon } from "@mui/material";

export const LocationPinIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 1.99996C6.76232 1.99996 5.57534 2.49162 4.70017 3.36679C3.825 4.24196 3.33333 5.42895 3.33333 6.66663C3.33333 8.40327 4.51365 10.241 5.83161 11.7237C6.47495 12.4475 7.12036 13.0528 7.60567 13.4774C7.75412 13.6073 7.88715 13.7199 8 13.8134C8.11285 13.7199 8.24588 13.6073 8.39433 13.4774C8.87964 13.0528 9.52505 12.4475 10.1684 11.7237C11.4864 10.241 12.6667 8.40327 12.6667 6.66663C12.6667 5.42895 12.175 4.24196 11.2998 3.36679C10.4247 2.49162 9.23768 1.99996 8 1.99996ZM8 14.6666C7.6 15.2 7.59985 15.1999 7.59969 15.1997L7.59925 15.1994L7.59802 15.1985L7.59411 15.1955L7.58061 15.1853C7.56912 15.1765 7.55272 15.1639 7.53172 15.1477C7.48974 15.1151 7.42937 15.0678 7.35324 15.0066C7.20103 14.8843 6.98548 14.7064 6.72766 14.4808C6.21297 14.0305 5.52505 13.3858 4.83506 12.6095C3.48635 11.0922 2 8.92998 2 6.66663C2 5.07533 2.63214 3.5492 3.75736 2.42399C4.88258 1.29877 6.4087 0.666626 8 0.666626C9.5913 0.666626 11.1174 1.29877 12.2426 2.42399C13.3679 3.5492 14 5.07533 14 6.66663C14 8.92998 12.5136 11.0922 11.1649 12.6095C10.4749 13.3858 9.78703 14.0305 9.27234 14.4808C9.01452 14.7064 8.79897 14.8843 8.64676 15.0066C8.57063 15.0678 8.51026 15.1151 8.46828 15.1477C8.44728 15.1639 8.43088 15.1765 8.41939 15.1853L8.40589 15.1955L8.40198 15.1985L8.40074 15.1994L8.40031 15.1997C8.40015 15.1999 8.4 15.2 8 14.6666ZM8 14.6666L8.4 15.2C8.16296 15.3777 7.83704 15.3777 7.6 15.2L8 14.6666Z"
      fill="currentColor"
    />
    <path
      d="M7.99967 5.33333C7.26329 5.33333 6.66634 5.93029 6.66634 6.66667C6.66634 7.40305 7.26329 8 7.99967 8C8.73605 8 9.33301 7.40305 9.33301 6.66667C9.33301 5.93029 8.73605 5.33333 7.99967 5.33333ZM5.33301 6.66667C5.33301 5.19391 6.52692 4 7.99967 4C9.47243 4 10.6663 5.19391 10.6663 6.66667C10.6663 8.13943 9.47243 9.33333 7.99967 9.33333C6.52692 9.33333 5.33301 8.13943 5.33301 6.66667Z"
      fill="currentColor"
    />
  </svg>,
  "LocationPinIcon"
);
