import andifyArray from "helpers/andifyArray";
import { useTranslation } from "react-i18next";
import { ProfileAddress } from "types/ProfileAddress";
import { isDefined } from "types/typeGuards/PrimitiveTypeGuards";

const useAddressLabel = (address: ProfileAddress | undefined) => {
  const { t } = useTranslation("common");

  if (!address) {
    return "";
  }

  const building = address.building ? `${t("building")} ${address.building}` : undefined;
  const street = address.street ? `${t("street")}  ${address.street}` : undefined;
  const block = address.block ? `${t("block")} ${address.block}` : undefined;
  const floor = address.floor ? `${t("floor")} ${address.floor}` : undefined;
  const flat = address.flatNumber ? `${t("flat")} ${address.flatNumber}` : undefined;
  const avenue = address.avenue ? `${t("avenue")} ${address.avenue}` : undefined;

  return andifyArray([block, street, avenue, building, floor, flat].filter(isDefined), "");
};

export default useAddressLabel;
