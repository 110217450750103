import { ProfileAddress } from "types/ProfileAddress";
import http from "utils/http";

type CreateAddressPayload = Pick<
  ProfileAddress,
  "area" | "block" | "building" | "floor" | "flatNumber" | "street" | "avenue"
>;

const profileAddressesApi = {
  getProfileAddresses: async () => http.get<ProfileAddress[]>(`api/addresses/profile-addresses/`),

  createProfileAddress: async (address: CreateAddressPayload) =>
    http.post<ProfileAddress>(`api/addresses/profile-addresses/`, address),

  updateProfileAddress: async (address: ProfileAddress) =>
    http.put<ProfileAddress>(`api/addresses/profile-addresses/${address.id}/`, address),

  deleteProfileAddress: async (address: ProfileAddress) =>
    http.delete(`api/addresses/profile-addresses/${address.id}/`),
};

export default profileAddressesApi;
