import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import { sentryReduxEnhancer } from "./../configs/sentry";
import appointments from "./slices/appointments/appointmentsSlice";
import articles from "./slices/articles/articlesSlice";
import articleSlots from "./slices/articleSlots/articleSlotsSlice";
import auth from "./slices/auth/authSlice";
import cart from "./slices/cart/cartSlice";
import employees from "./slices/employees/employeesSlice";
import onlinePaymentRules from "./slices/onlinePaymentRules/onlinePaymentRulesSlice";
import onlinePayments from "./slices/onlinePayments/onlinePaymentsSlice";
import organization from "./slices/organization/organizationSlice";
import outlets from "./slices/outlets/outletsSlice";
import packageCategories from "./slices/packageCategories/packageCategoriesSlice";
import packages from "./slices/packages/packagesSlice";
import packageVariants from "./slices/packageVariants/packageVariantsSlice";
import profileAddresses from "./slices/profileAddresses/profileAddressesSlice";
import serviceCategories from "./slices/serviceCategories/serviceCategoriesSlice";
import services from "./slices/services/servicesSlice";
import serviceVariants from "./slices/serviceVariants/serviceVariantsSlice";
import subscriptionPurchases from "./slices/subscriptionPurchases/subscriptionPurchasesSlice";
import subscriptions from "./slices/subscriptions/subscriptionsSlice";
import wallet from "./slices/wallet/walletSlice";
import zones from "./slices/zones/zoneSlice";
import areas from "./slices/areas/areasSlice";
import cities from "./slices/cities/citySlice";

export { default as useDispatch } from "./utils/useDispatch";
export { default as useSelector } from "./utils/useSelector";

const rootReducer = combineReducers({
  auth,
  appointments,
  articles,
  articleSlots,
  cart,
  employees,
  onlinePaymentRules,
  onlinePayments,
  organization,
  outlets,
  packageCategories,
  packages,
  packageVariants,
  serviceCategories,
  services,
  serviceVariants,
  subscriptions,
  subscriptionPurchases,
  wallet,
  areas,
  cities,
  zones,
  profileAddresses,
});

const globalReducer = (state: any, action: AnyAction) => {
  return rootReducer(state, action);
};

const store = configureStore({
  reducer: globalReducer,

  devTools: process.env.NODE_ENV !== "production",

  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

export default store;

// Some thoughts on loading, Redux status, and skeletons

/**
 *
 * I am thinking, why not create some composite loading selectors for pages.
 *
 * We are keeping track of each store's status uniquely; we can make selectors
 * that subscribe to all loading states that page cares about, and then we
 * just do something like
 *
 *     const isHomePageLoading = useSelector(selectHomePageLoading);
 *
 * Then instead of making every individual component's skeleton we just create
 * them by page. We save time on CSS composition and the code is much easier
 * for a quick read, but if you need to know what states they select you just
 * just look at definition
 *
 * - hasan
 */
