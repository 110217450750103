import { createSvgIcon } from "@mui/material";

export const DeleteIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5C5.55228 5 6 5.44772 6 6V20C6 20.1748 6.09745 20.4332 6.33211 20.6679C6.56676 20.9025 6.82523 21 7 21H17C17.1748 21 17.4332 20.9025 17.6679 20.6679C17.9025 20.4332 18 20.1748 18 20V6C18 5.44772 18.4477 5 19 5C19.5523 5 20 5.44772 20 6V20C20 20.8252 19.5975 21.5668 19.0821 22.0821C18.5668 22.5975 17.8252 23 17 23H7C6.17477 23 5.43324 22.5975 4.91789 22.0821C4.40255 21.5668 4 20.8252 4 20V6C4 5.44772 4.44772 5 5 5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33211 3.33211C9.09745 3.56676 9 3.82523 9 4V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V4C7 3.17477 7.40255 2.43324 7.91789 1.91789C8.43324 1.40255 9.17477 1 10 1H14C14.8252 1 15.5668 1.40255 16.0821 1.91789C16.5975 2.43324 17 3.17477 17 4V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V4C15 3.82523 14.9025 3.56676 14.6679 3.33211C14.4332 3.09745 14.1748 3 14 3H10C9.82523 3 9.56676 3.09745 9.33211 3.33211Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
    />
  </>,
  "DeleteIcon"
);
