import { Backdrop } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAreProfileAddressesLoading, selectProfileAddresses } from "store/selectors";
import { ProfileAddress } from "types/ProfileAddress";

import ClientAddressForm from "./ClientAddressForm";
import ClientAddressSectionSkeleton from "./ClientAddressSection.skeleton";
import ExistingAddressesView from "./ExistingAddressesView";

type ClientAddressesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isAddAddressMode?: boolean;

  selectedAreaId: Nullable<number>;

  selectedAddressId: Nullable<number>;
  handleChangeAddress: (addressId: number | null) => void;
  handleContinueClick: () => void;
};

const ClientAddressesModal = ({
  isOpen,
  onClose,
  isAddAddressMode,
  selectedAreaId,
  selectedAddressId,
  handleChangeAddress,
  handleContinueClick,
}: ClientAddressesModalProps) => {
  const { t } = useTranslation(["reviewAppointmentPage", "common"]);
  const areAddressesLoading = useSelector(selectAreProfileAddressesLoading);
  const profileAddress = useSelector(selectProfileAddresses);

  const [shouldShowAddressForm, setShouldShowAddressForm] = useState(isAddAddressMode);

  const showAddressForm = () => {
    setShouldShowAddressForm(true);
  };

  const handleSaveForm = useCallback(
    (address: ProfileAddress) => {
      if (address?.id) {
        handleChangeAddress(address?.id);
      }

      handleContinueClick();
    },
    [handleChangeAddress, handleContinueClick]
  );

  const handleCloseModal = useCallback(() => {
    onClose();

    // Reset the form visibility state to its initial state
    setShouldShowAddressForm(isAddAddressMode);
  }, [onClose, isAddAddressMode]);

  const hasAnyAddress = profileAddress.length > 0;

  const renderDrawerContent = useMemo(() => {
    if (areAddressesLoading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <ClientAddressSectionSkeleton key={index} />
      ));
    }

    if (shouldShowAddressForm || !hasAnyAddress) {
      return (
        <ClientAddressForm
          handleSaveForm={handleSaveForm}
          closeForm={handleCloseModal}
          selectedAreaId={selectedAreaId}
        />
      );
    }

    return (
      <ExistingAddressesView
        selectedAreaId={selectedAreaId}
        selectedAddressId={selectedAddressId}
        handleToggleSelection={handleChangeAddress}
        handleAddAddressClick={showAddressForm}
        handleContinueClick={handleContinueClick}
        handleCancelClick={handleCloseModal}
        continueButtonProps={{
          disabled: !selectedAddressId,
        }}
      />
    );
  }, [
    areAddressesLoading,
    shouldShowAddressForm,
    hasAnyAddress,
    selectedAreaId,
    selectedAddressId,
    handleChangeAddress,
    handleContinueClick,
    handleCloseModal,
    handleSaveForm,
  ]);

  return (
    <>
      <Backdrop open={isOpen} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }} />

      <DrawerModal
        variant="persistent"
        title={
          isAddAddressMode
            ? t("reviewAppointmentPage:enterYourAddress")
            : t("reviewAppointmentPage:pickAnAddress")
        }
        titleProps={{
          sx: { px: 2 },
        }}
        showClose={false}
        open={isOpen}
        onOpen={() => {}}
        onClose={onClose}
        PaperProps={{ sx: { py: 3 } }}
      >
        {renderDrawerContent}
      </DrawerModal>
    </>
  );
};

export default ClientAddressesModal;
