import { Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { AreaSelectorModal } from "components/AreaSelectorModal";
import { NumberOfPeopleModal } from "components/NumberOfPeopleModal";
import { TimeSlotSelectorModal } from "components/TimeSlotSelectorModal";
import generateCartArticle from "helpers/article/generateCartArticle";
import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import {
  ApiHomeServiceSlot,
  slotsApi,
} from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import {
  selectAllPackages,
  selectAllServiceVariants,
  selectAllServices,
  selectCart,
  selectHasFilledHomeServiceAppointmentDetails,
  selectHomeServiceArea,
  selectHomeServiceNumOfPeople,
  selectIsOutletsLoading,
  selectOrganization,
  selectPackageVariantWithServicesById,
  selectSessionId,
} from "store/selectors";
import { selectAllAreas } from "store/slices/areas/areasSelectors";
import cartApi from "store/slices/cart/cartApi";
import {
  addArticleToCart,
  setArea,
  setCartAddress,
  setNumOfPeople,
  setSessionId,
} from "store/slices/cart/cartSlice";
import { selectAllCities } from "store/slices/cities/citySelectors";
import { selectAllZones } from "store/slices/zones/zoneSelectors";
import { checkIsHomeServiceOrganization } from "utils/organization";
import { notify } from "utils/SnackbarProvider";

import HomeServiceAppointmentDetails from "./HomeServiceAppointmentDetails";
import OutletBookingContext from "./outletContext";
import OutletPageSkeleton from "./OutletPage.skeleton";
import OutletPageHeader from "./OutletPageHeader";
import ScrollableDatePickerButtons from "./ScrollableDatePickerButtons";
import ServiceAndStaffListTabs from "./ServiceAndStaffListTabs";
import StartBookingButton from "./StartBookingButton";

enum HomeServiceBookingModals {
  Area = "Area",
  NumberOfPeople = "NumberOfPeople",
  Availability = "Availability",
}

export default function OutletPage() {
  const { t } = useTranslation(["bookingPage", "outletPage"]);

  const outlet = useParamOutlet();
  const organization = useSelector(selectOrganization);

  const areas = useSelector(selectAllAreas);
  const cities = useSelector(selectAllCities);
  const zones = useSelector(selectAllZones);
  const variants = useSelector(selectAllServiceVariants);
  const services = useSelector(selectAllServices);
  const packages = useSelector(selectAllPackages);

  const [isLoadingAvailability, setIsLoadingAvailability] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [selectedPurchasableAvailability, setSelectedPurchasableAvailability] = useState<
    ApiHomeServiceSlot[] | null
  >();

  const sessionId = useSelector(selectSessionId);

  const {
    selectedPurchasable,
    selectedDate: date,
    selectedPurchasableNumberOfPeople,
    setSelectedPurchasable,
    setSelectedPurchasablePackage,
    selectedPurchasablePackage,
  } = useContext(OutletBookingContext);

  const selectedPurchasablePackageVariant = useSelector(
    selectPackageVariantWithServicesById(selectedPurchasablePackage || 0)
  );

  const isSelectedPurchasablePackage = !!selectedPurchasablePackage;

  const selectedArea = useSelector(selectHomeServiceArea);
  const selectedNumberOfPeople = useSelector(selectHomeServiceNumOfPeople);

  const hasFilledHomeServiceAppointmentDetails = useSelector(
    selectHasFilledHomeServiceAppointmentDetails
  );

  const dispatch = useDispatch();
  const cart = useSelector(selectCart);

  const area = useSelector(selectHomeServiceArea);

  const fetchAvailability = async () => {
    setIsLoadingAvailability(true);
    if (!area || !date || !selectedPurchasable || !selectedPurchasableNumberOfPeople) {
      return;
    }

    return (
      isSelectedPurchasablePackage
        ? slotsApi.getHomeServicePackageSlots({
            area,
            peopleCount: selectedPurchasableNumberOfPeople,
            date: date.toFormat("yyyy-MM-dd"),
            packageVariantId: selectedPurchasablePackage,
          })
        : slotsApi.getHomeServiceSlots({
            area,
            peopleCount: selectedPurchasableNumberOfPeople,
            date: date.toFormat("yyyy-MM-dd"),
            serviceVariantId: selectedPurchasable,
          })
    )
      .then(({ data }) => {
        if (data.slots && data.slots.length > 0) {
          setSelectedPurchasableAvailability(data.slots);
          setIsLoadingAvailability(false);
          setActiveBookingModal(HomeServiceBookingModals.Availability);
        } else {
          notify(t("outletPage:notAvailable"), "error");

          setIsLoadingAvailability(false);
        }
      })
      .catch((e) => {
        setIsLoadingAvailability(false);
        if (e.response?.data?.detail) {
          notify(e.response.data.detail, "error");
        } else {
          notify(t("outletPage:notAvailable"), "error");
        }
      });
  };

  const handleBookingFirstService = () => {
    if (selectedPurchasableAvailability) {
      setSelectedPurchasableAvailability(null);
    }
    fetchAvailability();
  };

  const handleBookingOtherService = () => {
    if (selectedPurchasableAvailability) {
      setSelectedPurchasableAvailability(null);
    }
    addOtherServiceToCart();
  };

  const addOtherServiceToCart = async () => {
    setIsLoadingDraft(true);
    await cartApi
      .createDraftArticle({
        area: selectedArea!,
        date: date.toFormat("yyyy-MM-dd"),
        peopleCount: selectedPurchasableNumberOfPeople!,
        serviceVariant: selectedPurchasablePackage ? undefined : selectedPurchasable!,
        packageVariant: selectedPurchasablePackage || undefined,
        outlet: outlet.id,
        sessionId: sessionId!,
      })
      .then(({ data }) => {
        const draft = data?.[0];

        const articleVariant = variants.find((variant) => variant.id === selectedPurchasable);

        const articleService = services.find((service) => service.id === articleVariant?.service);

        const articlePackage = packages.find(
          (pkg) => pkg.id === selectedPurchasablePackageVariant?.package
        );

        const startTimeString =
          draft?.start.slice(-1) === "Z" ? draft?.start.slice(0, -1) : draft?.start;

        const cartArticle = isSelectedPurchasablePackage
          ? generateCartArticle(
              DateTime.fromISO(startTimeString),
              0,
              null,
              articlePackage?.depositPercentage || 0,
              undefined,
              selectedPurchasablePackageVariant,
              selectedPurchasableNumberOfPeople || undefined
            )
          : generateCartArticle(
              DateTime.fromISO(startTimeString),
              [],
              null,
              articleService?.depositPercentage || 0,
              articleVariant,
              undefined,
              selectedPurchasableNumberOfPeople || undefined
            );
        if (!cartArticle) {
          return;
        }

        setIsLoadingDraft(false);
        setSelectedPurchasable(null);
        if (isSelectedPurchasablePackage) {
          setSelectedPurchasablePackage(null);
        }
        dispatch(setSessionId(data?.[0]?.sessionId));
        dispatch(
          addArticleToCart({
            ...cartArticle,
            draftIds: data?.map((d) => d.id),
          })
        );
        setActiveBookingModal(null);
      })
      .catch((e) => {
        setIsLoadingDraft(false);
        if (e.response?.data?.detail) {
          notify(e.response.data.detail, "error");
        } else {
          notify(t("outletPage:notAvailable"), "error");
        }
      });
  };

  const addFirstServiceToCart = async (timeSlot: ApiHomeServiceSlot) => {
    const formattedDateTime = DateTime.fromISO(
      `${date.toFormat("yyyy-MM-dd")}T${timeSlot.startTime}+03:00`
    );

    const articleVariant = variants.find((variant) => variant.id === selectedPurchasable);

    const articleService = services.find((service) => service.id === articleVariant?.service);

    const articlePackage = packages.find(
      (pkg) => pkg.id === selectedPurchasablePackageVariant?.package
    );

    const cartArticle = isSelectedPurchasablePackage
      ? generateCartArticle(
          formattedDateTime,
          0,
          null,
          articlePackage?.depositPercentage || 0,
          undefined,
          selectedPurchasablePackageVariant,
          selectedPurchasableNumberOfPeople || undefined
        )
      : generateCartArticle(
          formattedDateTime,
          [],
          null,
          articleService?.depositPercentage || 0,
          articleVariant,
          undefined,
          selectedPurchasableNumberOfPeople || undefined
        );
    if (cartArticle) {
      setIsLoadingDraft(true);
      await cartApi
        .createDraftArticle({
          area: selectedArea!,
          date: date.toFormat("yyyy-MM-dd"),
          peopleCount: selectedPurchasableNumberOfPeople!,
          serviceVariant: selectedPurchasablePackage ? undefined : selectedPurchasable!,
          packageVariant: selectedPurchasablePackage || undefined,
          start: formattedDateTime.toISO({ includeOffset: false }),
          outlet: outlet.id,
        })
        .then(({ data }) => {
          setIsLoadingDraft(false);
          dispatch(setSessionId(data?.[0]?.sessionId));
          dispatch(
            addArticleToCart({
              ...cartArticle,
              draftIds: data?.map((d) => d.id),
            })
          );
          setActiveBookingModal(null);
          setSelectedPurchasable(null);
          if (isSelectedPurchasablePackage) {
            setSelectedPurchasablePackage(null);
          }
        })
        .catch((e) => {
          setIsLoadingDraft(false);
          if (e.response?.data?.detail) {
            notify(e.response.data.detail, "error");
          } else {
            notify(t("outletPage:notAvailable"), "error");
          }
        });
    }
  };

  const isLoading = useSelector(selectIsOutletsLoading);

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const [activeBookingModal, setActiveBookingModal] = useState<HomeServiceBookingModals | null>(
    null
  );

  if (isLoading || !outlet) return <OutletPageSkeleton />;

  const isOutletAcceptingOnlineBookings = outlet.isAcceptingOnlineBookings;

  return (
    <>
      <PageWrapper>
        <Grid container justifyContent="center">
          <Grid item xs={12} mb={1}>
            <OutletPageHeader />
          </Grid>
        </Grid>
        {(!isHomeService || hasFilledHomeServiceAppointmentDetails) && (
          <>
            <HomeServiceAppointmentDetails />
            <ScrollableDatePickerButtons />
          </>
        )}
        {organization.hasActiveSubscription ? (
          <>
            {!isOutletAcceptingOnlineBookings && (
              <Grid item p={2}>
                <Box display="flex" gap={2} sx={{ background: "#FFEED1" }} p={2}>
                  <img alt="try later" src="/images/WeAreBusy.svg" />

                  <Box>
                    <Typography fontWeight={700}> {t("weAreCurrentlyBusy")} </Typography>
                    <Typography color="#53627C" fontWeight={500} mt={1}>
                      {" "}
                      {t("pleaseCheckBackLaterText")}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}

            <Grid
              container
              justifyContent="center"
              sx={{
                pointerEvents: isOutletAcceptingOnlineBookings ? "" : "none",
                opacity: isOutletAcceptingOnlineBookings ? "none" : 0.5,
              }}
            >
              <Grid item xs={12}>
                <ServiceAndStaffListTabs />
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            display="flex"
            textAlign="center"
            flexDirection="column"
            width="100%"
            flex="1"
            alignItems="center"
            justifyContent="center"
            py={8}
            px={2}
            gap={1}
          >
            <img alt="Under Maintenance" src="/images/UnderMaintenance.svg" />
            <Typography>{t("underMaintenance")}</Typography>
          </Box>
        )}
      </PageWrapper>
      {isHomeService && (selectedPurchasable || !hasFilledHomeServiceAppointmentDetails) && (
        <Box
          position="sticky"
          bottom={0}
          width="100%"
          borderTop="1px solid #E6E9EC"
          bgcolor="white"
          p={2}
          display="flex"
          justifyContent="center"
        >
          <StartBookingButton
            loading={isLoadingAvailability || isLoadingDraft}
            onStartBooking={() => {
              setActiveBookingModal(HomeServiceBookingModals.Area);
            }}
            onAddToCart={() => {
              if (cart.articles.length === 0) {
                handleBookingFirstService();
              } else {
                handleBookingOtherService();
              }
            }}
          />
        </Box>
      )}
      {activeBookingModal === HomeServiceBookingModals.Area && (
        <AreaSelectorModal
          cities={cities}
          zones={zones}
          selectedAreaId={selectedArea}
          areas={areas}
          onSelect={(area) => {
            dispatch(setArea(area));
            dispatch(setCartAddress(null));

            setActiveBookingModal(HomeServiceBookingModals.NumberOfPeople);
          }}
          onClose={() => setActiveBookingModal(null)}
        />
      )}
      {activeBookingModal === HomeServiceBookingModals.NumberOfPeople && (
        <NumberOfPeopleModal
          numberOfPeople={selectedNumberOfPeople || 1}
          onSelect={(numOfPeople) => {
            dispatch(setNumOfPeople(numOfPeople));
            setActiveBookingModal(null);
          }}
          onClose={() => setActiveBookingModal(null)}
        />
      )}
      {!isLoadingAvailability && activeBookingModal === HomeServiceBookingModals.Availability && (
        <TimeSlotSelectorModal
          loading={isLoadingDraft}
          timeSlots={selectedPurchasableAvailability!}
          onClose={() => {
            setActiveBookingModal(null);
          }}
          onSelect={async (timeSlot) => {
            addFirstServiceToCart(timeSlot);
          }}
        />
      )}
    </>
  );
}

const PageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  position: "static",
}));
