import { LoadingButton } from "@mui/lab";
import { keyframes, styled } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectHasFilledHomeServiceAppointmentDetails } from "store/selectors";

import OutletBookingContext from "./outletContext";

export default function StartBookingButton({
  onStartBooking,
  onAddToCart,
  loading,
}: {
  onStartBooking?: () => void;
  onAddToCart?: () => void;
  loading: boolean;
}) {
  const { t } = useTranslation("bookingPage");

  const hasFilledHomeServiceAppointmentDetails = useSelector(
    selectHasFilledHomeServiceAppointmentDetails
  );

  const { selectedPurchasableNumberOfPeople } = useContext(OutletBookingContext);

  return (
    <ShakeButton
      fullWidth
      loading={loading}
      size="large"
      className="shake-button"
      disabled={hasFilledHomeServiceAppointmentDetails && !selectedPurchasableNumberOfPeople}
      onClick={() => {
        if (hasFilledHomeServiceAppointmentDetails) {
          onAddToCart?.();
        } else {
          onStartBooking?.();
        }
      }}
    >
      {hasFilledHomeServiceAppointmentDetails
        ? t("glossary:addToCart")
        : t("glossary:startBooking")}
    </ShakeButton>
  );
}

// Define the shake animation using keyframes
const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-10px);
  }
  40%, 80% {
    transform: translateX(10px);
  }
`;

const ShakeButton = styled(LoadingButton)`
  &.shake {
    animation: ${shake} 0.5s;
  }
`;
