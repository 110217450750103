import { Alert, Box, Button, ButtonProps, styled } from "@mui/material";
import { CartIcon } from "assets/icons/Cart";
import PurchasableItemsModal from "components/PurchasableItemsModal";
import { formatPrice } from "helpers/formatPrice";
import dontForwardProps from "helpers/styles/dontForwardProps";
import useParamOutlet from "hooks/useParamOutlet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectCart, selectOrganization } from "store/selectors";
import { selectAllZones } from "store/slices/zones/zoneSelectors";
import { trackEvent } from "tracking";
import { checkIsHomeServiceOrganization } from "utils/organization";

const GoToCartButton = ({
  prefix,
  onlyCheckout = false,
}: {
  prefix?: string;
  onlyCheckout?: boolean;
}) => {
  const outlet = useParamOutlet();
  const { t } = useTranslation("bookingPage");

  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const outletSlug = outlet.slug || "";

  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  const { area } = cart;

  const zones = useSelector(selectAllZones);

  const selectedZone = zones.find((zone) => zone.areas.includes(area!));

  let totalPrice = 0.0;

  cart.articles.forEach((article: CartArticle) => {
    if (!article.subscriptionPurchaseId) totalPrice += article.totalPrice;
  });

  const minimumCharge = parseFloat(selectedZone?.minimumCharge || "0");

  const hasReachedMinimumCharge = totalPrice >= minimumCharge;

  const shouldShowMinimumChargeWarning = isHomeService && !hasReachedMinimumCharge;

  const navigate = useNavigate();

  return (
    <>
      <PurchasableItemsModal open={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />

      {shouldShowMinimumChargeWarning && (
        <Alert
          variant="filled"
          severity="warning"
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            bgcolor: "#FFEED1",
            color: "#FF9800",
          }}
          icon={false}
        >
          {t("youNeedToSpend", {
            amount: `${t("currency:kwd")} ${formatPrice(minimumCharge - totalPrice)}`,
          })}
        </Alert>
      )}
      <Box display="flex" alignItems="center" columnGap={1} pb={3}>
        {onlyCheckout ? (
          <CustomCartSummaryButton
            variant="outlined"
            size="medium"
            onClick={() => {
              if (organization?.id && organization?.name) {
                trackEvent("Go to cart", { id: organization.id, name: organization.name }, {});
              }
              navigate(`/${outletSlug}/appointment-review`);
            }}
          >
            <CartIcon sx={{ width: "15px" }} /> {cart.articles.length}
          </CustomCartSummaryButton>
        ) : (
          <>
            <CustomCartSummaryButton
              variant="outlined"
              size="medium"
              onClick={() => {
                if (organization?.id && organization?.name) {
                  trackEvent(
                    "Explore cart items",
                    { id: organization.id, name: organization.name },
                    {}
                  );
                }
                setIsCartModalOpen(true);
              }}
            >
              <CartIcon sx={{ width: "15px" }} /> {cart.articles.length}
            </CustomCartSummaryButton>

            <CustomCartButton
              customColor="#53BFB0"
              disabled={shouldShowMinimumChargeWarning}
              sx={{ fontWeight: 700 }}
              fullWidth
              size="medium"
              variant="contained"
              onClick={() => {
                if (organization?.id && organization?.name) {
                  trackEvent("Go to cart", { id: organization.id, name: organization.name }, {});
                }
                navigate(`/${outletSlug}/appointment-review`);
              }}
            >
              {prefix}
            </CustomCartButton>
          </>
        )}
      </Box>
    </>
  );
};

type CustomCartButtonProps = ButtonProps & {
  customColor: string;
};

const CustomCartButton = styled(
  Button,
  dontForwardProps("customColor")
)<CustomCartButtonProps>(({ theme, customColor }) => ({
  border: `1px solid ${customColor}`,
  borderStyle: "solid",
  color: "white",
  fontWeight: 700,

  backgroundColor: customColor,

  display: "flex",
  flexDirection: "row",

  rowGap: theme.spacing(1),

  "&:hover": {
    border: `1px solid ${customColor}`,
    backgroundColor: customColor,
  },
  "&:active": {
    backgroundColor: customColor,
  },
}));

const CustomCartSummaryButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderStyle: "solid",

  fontWeight: 500,
  width: theme.spacing(6.75),

  display: "flex",
  flexDirection: "row",

  columnGap: theme.spacing(0.5),

  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default GoToCartButton;
