import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateAdapter, DirectionProvider, PeepThemeProvider } from "configs";
import { Settings } from "luxon";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getAreas } from "store/slices/areas/areasSlice";
import { getCities } from "store/slices/cities/citySlice";
import { getSubscriptionPurchases } from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import { getZones } from "store/slices/zones/zoneSlice";
import { SubscriptionStatus } from "types/Subscription";

import App from "./App";
import SplashPage from "./pages/SplashPage/SplashPage";
import { NotificationAlertContextProvider } from "./providers/notification";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { getNormalizedOrganization } from "./store/globalThunks";
import { getProfile } from "./store/slices/auth/authSlice";
import auth from "./utils/auth";
import SnackbarProvider from "./utils/SnackbarProvider";

Settings.defaultZone = "Asia/Kuwait";

store.dispatch(getNormalizedOrganization({}));
store.dispatch(getAreas({}));
store.dispatch(getCities({}));
store.dispatch(getZones({}));

if (auth.isLoggedIn) {
  store.dispatch(getProfile({}));
  store.dispatch(
    getSubscriptionPurchases({ subscriptionStatus: SubscriptionStatus.activeSubscriptions })
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DirectionProvider>
          <PeepThemeProvider>
            <Suspense fallback={<SplashPage />}>
              <CssBaseline />
              <SnackbarProvider />
              <BrowserRouter>
                <NotificationAlertContextProvider>
                  <App />
                </NotificationAlertContextProvider>
              </BrowserRouter>
            </Suspense>
          </PeepThemeProvider>
        </DirectionProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
