import { ChevronDown, Remove } from "@bookpeep/ui";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import ArticleItemRedeemableSubscriptions from "pages/BookingPage/ServiceBookingPage/ArticleItemRedeemableSubscriptions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveSubscriptionByOutletIdAndItemId,
  selectPackageVariantById,
} from "store/selectors";
import { RedeemableSubscriptionItemOption } from "types/Subscription";

import AppointmentArticleContainer from "../AppointmentArticleContainer";
import PackageArticleServiceVariantDetails from "./PackageArticleServiceVariantDetails";

type AppointmentPackageArticleProps = {
  selectedDate: DateTime;
  outletId: number;
  article: PackageCartArticle;
  shouldShowDeleteButton?: boolean;
  handleArticleDelete: () => void;
  handleArticleUpdate: (slots: CartArticleSlot[]) => void;
  handleSubscriptionPurchaseSelect: (subscriptionPurchaseId: Nullable<number>) => void;
  shouldShowNumberOfPeopleCount?: boolean;
};

const AppointmentPackageArticle = ({
  selectedDate,
  outletId,
  article,
  shouldShowDeleteButton = true,
  handleArticleDelete,
  handleArticleUpdate,
  handleSubscriptionPurchaseSelect,
  shouldShowNumberOfPeopleCount,
}: AppointmentPackageArticleProps) => {
  const { t } = useTranslation(["common", "glossary", "outletPage"]);

  const [isExpanded, setIsExpanded] = useState(false);

  const packageVariant = useSelector(selectPackageVariantById(article.packageVariant ?? ""));

  const subscriptions = useSelector(
    selectActiveSubscriptionByOutletIdAndItemId(
      outletId,
      article.packageVariant,
      RedeemableSubscriptionItemOption.PackageVariant
    )
  );

  const shouldShowRedeemableSubscriptions = subscriptions?.length > 0;

  if (!packageVariant) return <CentredSpinnerBox />;

  const isPackageParallel = packageVariant.parallel;

  const alreadyOccupiedEmployeesInArticle = isPackageParallel
    ? (article.slots.filter((slot) => !!slot.staff).map((slot) => slot.staff) as number[])
    : ([] as number[]);

  const startOfFirstService = article.slots[0]?.start;
  const endOfLastService = article.slots[article.slots.length - 1]?.end;

  const handleToggleSubscriptionActivation = (subscriptionId: Nullable<number>) => {
    handleSubscriptionPurchaseSelect(
      article.subscriptionPurchaseId === subscriptionId ? null : subscriptionId
    );
  };

  return (
    <AppointmentArticleContainer>
      <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
        <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column" rowGap={1} height="100%" pb={2}>
            <Typography variant="h4">{t(packageVariant.t.name)}</Typography>

            <Typography variant="primary">{`${fromTofyTimes(
              DateTime.fromISO(startOfFirstService),
              DateTime.fromISO(endOfLastService)
            )} • ${getFormattedDuration(packageVariant.totalDuration)}`}</Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <PriceBox
              quantity={shouldShowNumberOfPeopleCount ? Number(article.numberOfPeople) : undefined}
              isFree={!!article.subscriptionPurchaseId}
              value={Number(packageVariant.price)}
              decimalStrategy="trim_zeros"
              variant="h4"
              textSx={{ color: "black", fontWeight: 400 }}
              flexDirection={shouldShowNumberOfPeopleCount ? "row" : "column"}
            />

            {shouldShowDeleteButton && (
              <Remove
                sx={{ fontSize: "1.5rem", color: "error.main", cursor: "pointer" }}
                onClick={handleArticleDelete}
              />
            )}
          </Box>
        </Box>

        {shouldShowRedeemableSubscriptions && (
          <Box display="flex" py={2} justifyContent="space-between">
            <Grid container spacing={1}>
              <ArticleItemRedeemableSubscriptions
                subscriptions={subscriptions}
                appliedSubscription={article.subscriptionPurchaseId}
                handleToggleSubscriptionActivation={handleToggleSubscriptionActivation}
                selectedDate={selectedDate || DateTime.now()}
              />
            </Grid>
          </Box>
        )}

        <Box display="flex" flexDirection="row" justifyContent="center">
          <Button
            fullWidth
            variant="text"
            color="secondary"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="secondary">
                {isExpanded
                  ? t("outletPage:hidePackageDetails")
                  : t("outletPage:showPackageDetails")}
              </Typography>

              <ChevronDown />
            </Box>
          </Button>
        </Box>

        {isExpanded &&
          article.slots.map((slot, index) => (
            <Box
              key={slot.serviceVariant}
              display="flex"
              flexDirection="column"
              gap={1}
              justifyContent="center"
            >
              <PackageArticleServiceVariantDetails
                index={index}
                slot={slot}
                blockedEmployeeIds={alreadyOccupiedEmployeesInArticle.filter(
                  (id) => id !== slot.staff
                )}
                handleArticleUpdate={(slot: CartArticleSlot) =>
                  handleArticleUpdate(
                    article.slots.map((articleSlot, idx) => (idx === index ? slot : articleSlot))
                  )
                }
              />
            </Box>
          ))}
      </Box>
    </AppointmentArticleContainer>
  );
};

export default AppointmentPackageArticle;
