import { makeFieldRequired } from "utils/formValidation";
import { NumberWithProperRequiredError } from "utils/formValidation/NumberWithProperRequiredError";
import { z } from "zod";

export const profileAddressFormSchema = z.object({
  id: z.number().optional(),
  area: NumberWithProperRequiredError(),
  block: z.string().max(100).superRefine(makeFieldRequired),
  street: z.string().max(100).superRefine(makeFieldRequired),
  building: z.string().max(100).superRefine(makeFieldRequired),
  avenue: z.string().max(100),
  flatNumber: z.string().max(100),
  floor: z.string().max(100),
});

export type ProfileAddressFormFields = z.infer<typeof profileAddressFormSchema>;
