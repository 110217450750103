import { Button, Stack } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAreProfileAddressesLoading, selectProfileAddresses } from "store/selectors";
import { ProfileAddress } from "types/ProfileAddress";

import ClientAddressSection from "./ClientAddressSection";
import ClientAddressSectionSkeleton from "./ClientAddressSection.skeleton";
import ModalFooterButtons, { ModalFooterButtonsProps } from "./ModalFooterButtons";

type ExistingAddressesViewProps = ModalFooterButtonsProps & {
  selectedAreaId: Nullable<number>;

  selectedAddressId: number | null;
  handleToggleSelection?: (addressId: number | null) => void;
  handleAddAddressClick: () => void;
};

const ExistingAddressesView = ({
  selectedAreaId,
  selectedAddressId,
  handleToggleSelection,
  handleAddAddressClick,

  ...modalFooterButtonsProps
}: ExistingAddressesViewProps) => {
  const { t } = useTranslation(["reviewAppointmentPage", "common"]);
  const areAddressesLoading = useSelector(selectAreProfileAddressesLoading);
  const profileAddress = useSelector(selectProfileAddresses);

  const renderAddresses = useMemo(() => {
    if (areAddressesLoading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <ClientAddressSectionSkeleton key={index} />
      ));
    }

    const checkIfAddressCanBeSelected = (address: ProfileAddress) =>
      selectedAreaId ? selectedAreaId === address.area : true;

    // Addresses that can be selected should be shown first
    const sortedAddressBasedOnIfAddressCanBeSelected = [...(profileAddress || [])]?.sort((a, b) => {
      const aCanBeSelected = checkIfAddressCanBeSelected(a);
      const bCanBeSelected = checkIfAddressCanBeSelected(b);

      return aCanBeSelected === bCanBeSelected ? 0 : aCanBeSelected ? -1 : 1;
    });

    return sortedAddressBasedOnIfAddressCanBeSelected?.map((address) => (
      <ClientAddressSection
        key={address.id}
        isSelected={!!address?.id && selectedAddressId === address?.id}
        handleToggleSelection={() =>
          handleToggleSelection && handleToggleSelection(address.id || null)
        }
        hasRadioButton
        address={address}
        isDisabled={!checkIfAddressCanBeSelected(address)}
        isEditFormOpen={false}
      />
    ));
  }, [
    areAddressesLoading,
    profileAddress,
    selectedAreaId,
    selectedAddressId,
    handleToggleSelection,
  ]);

  return (
    <>
      <Stack gap={2} p={2} overflow="scroll">
        <Button variant="outlined" onClick={handleAddAddressClick}>
          {t("reviewAppointmentPage:addNewAddress")}
        </Button>

        {renderAddresses}
      </Stack>

      <ModalFooterButtons {...modalFooterButtonsProps} />
    </>
  );
};

export default ExistingAddressesView;
