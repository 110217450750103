import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { isRejected } from "@reduxjs/toolkit";
import PasswordField from "components/PasswordField";
import getErrorDetailTranslationKey from "helpers/errors/getErrorDetailTranslationKey";
import useAlertBox from "hooks/useAlertBox";
import useFormValidation from "hooks/useFormValidation";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "store";
import { resetPassword, validateResetToken } from "store/slices/auth/authSlice";
import { isAxiosErrorOfResponseType } from "types/helpers";
import { notify } from "utils/SnackbarProvider";
import * as yup from "yup";

const resetPasswordSchema = yup.object({
  password: yup.string().required().max(255).min(8),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null])
    .max(255)
    .min(8),
});

type ResetPasswordInputs = yup.InferType<typeof resetPasswordSchema>;

export default function ResetPasswordForm() {
  const { t } = useTranslation(["auth", "errors"]);
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const hasValidatedToken = useRef(false);

  const [isRequestPending, setIsRequestPending] = useState(false);

  const { AlertBox, setAlert, clearAlert } = useAlertBox();

  const { handleSubmit, formState, register } = useForm<ResetPasswordInputs>({
    resolver: yupResolver(resetPasswordSchema),

    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const registerValidation = useFormValidation(formState, { ns: "auth" });

  useEffect(() => {
    if (token && !hasValidatedToken.current) {
      dispatch(validateResetToken({ token }))
        .unwrap()
        .then(() => {})
        .catch((error) => {
          notify(`${t("invalidToken")} ${error?.detail ?? ""}`, "error", { shouldPersist: true });
          // redirect to the forgot-password page, so that the user can request to reset their password again and get out of this endless dispatching loop
          navigate("/forgot-password");
        });
      hasValidatedToken.current = true;
    }
  }, [dispatch, setAlert, navigate, t, token]);

  const handleResetPassword = handleSubmit(({ password }) => {
    setIsRequestPending(true);
    clearAlert();

    dispatch(
      resetPassword({
        token: token || "",
        password,
      })
    ).then((action) => {
      setIsRequestPending(false);

      if (isRejected(action)) {
        if (isAxiosErrorOfResponseType(action.payload) && action.payload.response) {
          const responseData = action.payload.response.data;

          if (responseData.detail) {
            const errorMessageTranslationKey = getErrorDetailTranslationKey(responseData.detail);

            setAlert({
              message: t(errorMessageTranslationKey),
              severity: "error",
            });
          }
        }
      } else {
        notify(t("passwordResetSuccessfully"), "success", {
          shouldPersist: false,
        });

        navigate("/login", { replace: true });
      }
    });
  });

  return (
    <>
      <Grid item xs={12}>
        <AlertBox />
      </Grid>

      <Grid item xs={12} container spacing={3} component="form" onSubmit={handleResetPassword}>
        <Grid item xs={12}>
          <PasswordField
            fullWidth
            label={t("password")}
            placeholder={t("passwordPlaceholder")}
            {...register("password")}
            {...registerValidation("password")}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordField
            fullWidth
            label={t("confirmPassword")}
            placeholder={t("confirmPasswordPlaceholder")}
            {...register("confirmPassword")}
            {...registerValidation("confirmPassword")}
          />
        </Grid>

        <Grid item xs={12} mt={3}>
          <LoadingButton fullWidth type="submit" loading={isRequestPending} size="medium">
            {t("changePassword")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
