import { Ticket } from "@bookpeep/ui";
import { Box, Card as MuiCard, Typography, styled } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectSubscriptionPurchaseByIdWithSubscriptionDetails } from "store/selectors";
import { Subscription, SubscriptionPurchase } from "types/Subscription";

import SubscriptionCardAction from "./SubscriptionCardAction";

type SubscriptionCardProps = {
  subscriptionPurchaseId: SubscriptionPurchase["id"];
  orgHasSubscriptionWithMultipleServiceVariants: boolean;
};

type SubscriptionPurchaseWithSubscription = SubscriptionPurchase & {
  subscription: Nullable<Subscription>;
};

const SubscriptionCard = ({
  subscriptionPurchaseId,
  orgHasSubscriptionWithMultipleServiceVariants,
}: SubscriptionCardProps) => {
  const { t } = useTranslation("userSubscriptionsPage");

  const subscriptionPurchase = useSelector(
    selectSubscriptionPurchaseByIdWithSubscriptionDetails(subscriptionPurchaseId)
  ) as SubscriptionPurchaseWithSubscription;

  if (!subscriptionPurchase) return <CentredSpinnerBox />;

  const subscription = subscriptionPurchase.subscription;

  const usedCredit =
    subscriptionPurchase.totalCreditsCount - subscriptionPurchase.remainingRedemptionsCount;

  const subscriptionExpirationDate = DateTime.fromISO(subscriptionPurchase.expirationDate);

  const isExpired =
    subscriptionPurchase.remainingRedemptionsCount <= 0 ||
    subscriptionExpirationDate < DateTime.now();

  const getSubscriptionStatus = () => {
    if (subscriptionPurchase.remainingRedemptionsCount <= 0) {
      return (
        <Typography fontWeight={500} color="text.label">
          {t("finished")}
        </Typography>
      );
    } else if (subscriptionExpirationDate < DateTime.now()) {
      return (
        <Typography fontWeight={500}>
          <Box component="span" color="error.main">
            {t("expired")}
          </Box>{" "}
          {t("on")} {subscriptionExpirationDate.toFormat("d LLL, yyyy")}
        </Typography>
      );
    } else {
      return (
        <Typography fontWeight={500}>
          {subscriptionPurchase.remainingRedemptionsCount} {t("left")}{" "}
          <Box component="span" color="text.label">
            {t("until")}
          </Box>{" "}
          {subscriptionExpirationDate.toFormat("d LLL, yyyy")}
        </Typography>
      );
    }
  };

  const getSubscriptionAvailability = () => {
    if (isExpired && subscription && !subscription.active) {
      return (
        <Typography textAlign="center" color="text.label" fontWeight={500}>
          {t("subscriptionNoLongerAvailable")}
        </Typography>
      );
    } else {
      return (
        <Typography textAlign="center" color="text.label" fontWeight={500}>
          {t("usedCreditMessage", {
            usedCredit,
            totalCredit: subscriptionPurchase.totalCreditsCount,
          })}
        </Typography>
      );
    }
  };

  return (
    <Card>
      <Box p={2}>
        <Typography
          display="flex"
          alignItems="center"
          flexWrap="nowrap"
          variant="h3"
          color="text.primary"
          fontWeight={700}
        >
          <Ticket sx={{ marginRight: "5px" }} />

          {subscriptionPurchase.name}
        </Typography>

        <Box display="flex" flexDirection="row" gap={0.5} pt={2} pb={1} height="100%">
          {getSubscriptionStatus()}
        </Box>

        <SubscriptionCardAction
          subscriptionPurchase={subscriptionPurchase}
          isExpired={isExpired}
          isSubscriptionActive={subscription && subscription.active}
          orgHasSubscriptionWithMultipleServiceVariants={
            orgHasSubscriptionWithMultipleServiceVariants
          }
        />

        <Box pt={2} pb={1} height="100%">
          {getSubscriptionAvailability()}
        </Box>
      </Box>
    </Card>
  );
};

export default SubscriptionCard;

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.bg.secondary,

  margin: theme.spacing(2, 0),

  "&:last-child": {
    marginBottom: 0,
  },
}));
