import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export type ModalFooterButtonsProps = {
  handleContinueClick: () => void;
  continueButtonProps?: LoadingButtonProps;
  cancelButtonProps?: ButtonProps;
  handleCancelClick: () => void;
};

const ModalFooterButtons = ({
  handleContinueClick,
  handleCancelClick,
  continueButtonProps,
  cancelButtonProps,
}: ModalFooterButtonsProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <Stack gap={1} pt={3}>
      <Divider />

      <Stack gap={1} pt={3} px={2}>
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={handleContinueClick}
          {...continueButtonProps}
        >
          {t("common:continue")}
        </LoadingButton>

        <Button fullWidth variant="text" onClick={handleCancelClick} {...cancelButtonProps}>
          {t("common:cancel")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ModalFooterButtons;
