import { Alert, Box, Divider, Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import useParamOutlet from "hooks/useParamOutlet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import {
  createAppointment,
  createHomeServiceAppointment,
} from "store/slices/appointments/appointmentsSlice";
import { getProfile } from "store/slices/auth/authSlice";
import { getCartDownPayment, selectCart, selectCartAddress } from "store/slices/cart/cartSelectors";
import { trackEvent } from "tracking";
import { PaymentType } from "types/common";
import { checkIsHomeServiceOrganization } from "utils/organization";
import { notify } from "utils/SnackbarProvider";
import useQuery from "utils/useQuery";

import CheckoutSummary from "./CheckoutSummary";
import PaymentMethodsList from "./PaymentMethodsList";
import PriceBadge from "./PriceBadge";

const CheckoutPage = () => {
  const { t } = useTranslation(["checkoutPage", "common", "errors", "glossary"]);

  const [paymentMethod, setPaymentMethod] = useState("knet");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const [hasPaymentFailed, setHasPaymentFailed] = useState(false);

  const navigate = useNavigate();
  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";
  const dispatch = useDispatch();
  const query = useQuery();
  const [isRedirectingToPayment, setIsRedirectingToPayment] = useState(false);

  const appointment = useSelector(selectCart);
  const organization = useSelector(selectOrganization);

  const cartAddress = useSelector(selectCartAddress);
  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);

  // TODO: pluck out as `useScrollToTop()` hook
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const paymentResult = query.get("Result") === "NOT CAPTURED";
    if (paymentResult) {
      if (organization?.id && organization?.name) {
        trackEvent("Payment Failed", { id: organization.id, name: organization.name }, {});
      }
      setHasPaymentFailed(true);
    }
  }, [query, organization]);

  let totalServices = 0.0;
  let totalPrice = 0.0;

  appointment.articles.forEach((article) => {
    totalServices += article.slots.length;
    if (!article.subscriptionPurchaseId) totalPrice += article.totalPrice;
  });

  const downPayment = useSelector(getCartDownPayment) || 0;

  if (!appointment || downPayment === null || isRedirectingToPayment) return <CentredSpinnerBox />;

  if (!appointment.articles.length && query.get("Result") !== "NOT CAPTURED")
    return <Navigate to={`/${outletSlug}/appointment-review`} />;

  const handleCreateAppointment = () => {
    setIsRequestLoading(true);

    const { articles, ...rest } = appointment;

    const data = {
      ...rest,
      articles: articles.map((article) => {
        const { ...articleRest } = article;
        return { ...articleRest };
      }),
      paymentMethod,
      address: isHomeService ? cartAddress : undefined,
    };

    const dispatchAction = isHomeService ? createHomeServiceAppointment : createAppointment;

    dispatch(dispatchAction(data))
      .unwrap()
      .then((response: any) => {
        const { payment, appointment } = response;

        dispatch(getProfile({}));

        if (payment?.paymentURL) {
          setIsRedirectingToPayment(true);
          window.location.href = payment.paymentURL;
        } else navigate(`/${outletSlug}/confirmation/${appointment.id}`);
      })
      .catch((error: any) => {
        setIsRequestLoading(false);
        notify(
          `${t("errors:createAppointment.couldNotCreateAppointment")} ${
            error?.response?.data?.detail ?? ""
          }`,
          "error",
          {
            shouldPersist: true,
          }
        );
      });
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center" p={2} bgcolor="#F6F7FC">
        <Grid item xs={12} pt={2}>
          <Typography variant="h1">{t("paymentSummary")}</Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <PriceBadge paymentType={PaymentType.DownPayment} paymentPrice={downPayment} />
        </Grid>

        {hasPaymentFailed && (
          <Grid item xs={12}>
            <Alert severity="error" variant="filled">
              {t("problemWithPayment")}
            </Alert>
          </Grid>
        )}
      </Grid>

      <Grid container bgcolor="white" display="flex" flexDirection="column" gap={1} p={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">{t("common:totalServicesPrice")}</Typography>

          <PriceBox value={totalPrice} variant="h4" />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight={400}>
            {t("common:downPayment")}
          </Typography>

          <PriceBox value={downPayment} variant="h4" textSx={{ fontWeight: 500 }} />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight={400}>
            {t("common:payAtStore")}
          </Typography>

          <PriceBox value={totalPrice - downPayment} variant="h4" textSx={{ fontWeight: 500 }} />
        </Box>

        <Divider variant="fullWidth" />
      </Grid>

      {Boolean(downPayment) && (
        <PaymentMethodsList
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          payablePrice={downPayment}
        />
      )}

      <CheckoutSummary
        totalItems={totalServices}
        totalPrice={totalPrice}
        paymentDue={downPayment}
        isRequestLoading={isRequestLoading}
        isPaymentSelected={!!paymentMethod}
        handleConfirm={handleCreateAppointment}
      />
    </>
  );
};

export default CheckoutPage;
