import * as Sentry from "@sentry/browser";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import api, { LoginResponse } from "store/slices/auth/authApi";

import http, { setBearerToken } from "./http";

const ACCESS_TOKEN = "auth_access";
const REFRESH_TOKEN = "auth_refresh";

class AuthenticationManager {
  constructor() {
    if (!!this.accessToken) setBearerToken(this.accessToken);
    else this.logout();
  }

  login(loginResponse: LoginResponse, shouldRememberUser: boolean) {
    const { access, refresh } = loginResponse;

    this.accessToken = access;

    if (shouldRememberUser) this.refreshToken = refresh;
    else this.sessionRefreshToken = refresh;

    Sentry.setTag("is_logged_in", "true");
  }

  logout() {
    this.accessToken = null;
    this.refreshToken = null;
    this.sessionRefreshToken = null;

    Sentry.setTag("is_logged_in", "false");
  }

  async refreshTokens() {
    if (!this.refreshToken) {
      auth.logout();
      return;
    }

    try {
      const response = await api.refreshToken(this.refreshToken);

      this.accessToken = response.data.access;

      return this.accessToken;
    } catch {
      this.logout();
    }
  }

  get isLoggedIn() {
    return !!this.accessToken;
  }

  private set accessToken(token: string | null) {
    if (token) localStorage.setItem(ACCESS_TOKEN, token);
    else localStorage.removeItem(ACCESS_TOKEN);

    setBearerToken(token);
  }

  private get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  private set refreshToken(token: string | null) {
    if (token) localStorage.setItem(REFRESH_TOKEN, token);
    else localStorage.removeItem(REFRESH_TOKEN);
  }

  private set sessionRefreshToken(token: string | null) {
    if (token) sessionStorage.setItem(REFRESH_TOKEN, token);
    else sessionStorage.removeItem(REFRESH_TOKEN);
  }

  private get refreshToken() {
    return localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN);
  }
}

const auth = new AuthenticationManager();

createAuthRefreshInterceptor(http, async (failedRequest) => {
  const newToken = await auth.refreshTokens();

  failedRequest.response.config.headers["Authorization"] = `Bearer ${newToken}`;

  return Promise.resolve();
});

export default auth;
