import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import PriceBox from "components/PriceBox";
import { formatPrice } from "helpers/formatPrice";
import { useTranslation } from "react-i18next";
import { PurchasableOptions } from "types/common";

type CheckoutSummaryProps = {
  totalPrice: number;
  totalItems: number;
  paymentDue: number;
  handleConfirm: () => void;
  isRequestLoading: boolean;
  isPaymentSelected: boolean;
  purchasableType?: string;
};

const CheckoutSummary = ({
  totalPrice,
  totalItems,
  paymentDue,
  handleConfirm,
  isRequestLoading,
  isPaymentSelected,
  purchasableType = PurchasableOptions.Service,
}: CheckoutSummaryProps) => {
  const { t } = useTranslation(["checkoutPage", "common", "glossary"]);

  return (
    <Grid container bgcolor="white" display="flex" flexDirection="column" gap={2} p={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {t("common:totalPrice")}
          {" • "}
          <Typography variant="primary">
            {t(
              `glossary:${
                purchasableType === PurchasableOptions.Subscription
                  ? "subscriptionCount"
                  : "serviceCount"
              }`,
              { count: totalItems }
            )}
          </Typography>
        </Typography>

        <PriceBox
          value={totalPrice}
          variant="h4"
          textSx={{ fontWeight: 500, color: "primary.main" }}
        />
      </Box>

      {!!paymentDue && totalPrice !== paymentDue && (
        <Typography variant="secondary">
          {t("remainingAmount", { amount: formatPrice(totalPrice - paymentDue) })}
        </Typography>
      )}

      {isPaymentSelected && (
        <LoadingButton
          loading={isRequestLoading}
          fullWidth
          onClick={() => handleConfirm()}
          size="medium"
        >
          {!!paymentDue ? t("confirm&Pay") : t("confirmBooking")}
        </LoadingButton>
      )}
    </Grid>
  );
};

export default CheckoutSummary;
