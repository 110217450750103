import { makeObjectFromString } from "helpers/makeObjectFromString";
import { FieldError, FieldValues, FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

// I made this type because Yup allows error messages to be functions but I am not sure how
// to handle those function messages in react-hook-form properly? Life is unfair
type ActualFieldError = Omit<FieldError, "message"> & {
  message?: string | { key: string; values: Record<string, string> };
};

type ExtraValidationHookOptions = {
  ns?: string;
};

export default function useFormValidation<FV extends FieldValues, FS extends FormState<FV>>(
  formState: FS | any,
  hookOptions?: ExtraValidationHookOptions
) {
  const { t } = useTranslation("validation");

  const ns = hookOptions?.ns || "common";

  /**
   * This function returns error and helper text for a field
   * @param fieldName
   * @param fieldLabelName // since zod doesn't have a way to specify a label for a field, this is the most convenient way to do it for now..
   * @returns error and helper text for a field if it has errors
   */

  const registerFormValidation = (fieldName: keyof FS["errors"], fieldLabelName = "") => {
    // react-hook-form does crazy shit with types under the hood, these types are guaranteed for v7.32.0
    // @ts-expect-error
    const fieldsObjects = makeObjectFromString(fieldName, formState?.errors);

    const fieldError = fieldsObjects as ActualFieldError;

    if (!fieldError) return {};

    const error = true;
    const { message, type } = fieldError;

    const translatedFieldName = t(String(fieldName), { ns });

    const translatedFieldLabelName = t(fieldLabelName, { ns });

    // Here I am returning the validation error type instead of the error message
    // We could possibly throw and force the dev to always display a proper message
    if (!message) return { error, helperText: type || t("unknownIssue") };
    else if (typeof message === "string") {
      return {
        error,
        helperText: t(message, { label: translatedFieldLabelName || translatedFieldName }),
      };
    }

    const { label: yupLabel, ...yupValues } = message.values;

    const label = translatedFieldLabelName || t(yupLabel, { ns }) || translatedFieldName;

    return { error, helperText: t(message.key, { label, ...yupValues }) };
  };

  return registerFormValidation;
}

/**
 * Dear teammates, you need to pass fieldLabelName if you want to have a custom label for the error message if you are using zod schema.
 */
