import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SliceStatus } from "store/slices/utils";
import createThunkFromApi from "store/utils/createThunkFromApi";

import api from "./zonesApi";

export const zonesAdapter = createEntityAdapter<Zone>({
  selectId: (zone) => zone.id,
});

export const getZones = createThunkFromApi("zones/getZones", api.getZones);

const initialState = zonesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const zoneSlice = createSlice({
  name: "zones",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getZones.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getZones.fulfilled, (state, { payload }) => {
        zonesAdapter.setAll(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getZones.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default zoneSlice.reducer;
