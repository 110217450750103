import { RootState } from "store";

import { citiesAdapter } from "./citySlice";

const selectCitiesState = (state: RootState) => state.cities;

const { selectById, selectAll } = citiesAdapter.getSelectors(selectCitiesState);

export const selectCityById = (id: Nullable<number | string>) => (state: RootState) =>
  selectById(state, id || "");

export const selectAllCities = selectAll;
