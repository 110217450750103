export enum Industry {
  Beauty = 1,
  Sports = 2,
  Other = 3,
  Clinics = 4,
  Spa = 5,
  BeautyHomeService = 6,
}

export const HomeServiceIndustries = [Industry.BeautyHomeService];

function checkIsHomeServiceOrganization(industry: number): boolean {
  return HomeServiceIndustries.includes(industry);
}

export { checkIsHomeServiceOrganization };
