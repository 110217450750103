import { createSvgIcon } from "@mui/material";

export const CartIcon = createSvgIcon(
  <path d="M5.6 1.7C5.69443 1.5741 5.84262 1.5 6 1.5H18C18.1574 1.5 18.3056 1.5741 18.4 1.7L21.4 5.7C21.4649 5.78655 21.5 5.89181 21.5 6V20C21.5 20.663 21.2366 21.2989 20.7678 21.7678C20.2989 22.2366 19.663 22.5 19 22.5H5C3.62386 22.5 2.5 21.3761 2.5 20V6C2.5 5.89181 2.53509 5.78655 2.6 5.7L5.6 1.7ZM6.25 2.5L4 5.5H20L17.75 2.5H6.25ZM20.5 6.40004C20.4164 6.46281 20.3126 6.5 20.2 6.5H3.8C3.68744 6.5 3.58357 6.46281 3.5 6.40004V20C3.5 20.8239 4.17614 21.5 5 21.5H19C19.3978 21.5 19.7794 21.342 20.0607 21.0607C20.342 20.7794 20.5 20.3978 20.5 20V6.40004ZM8 9.5C8.27614 9.5 8.5 9.72386 8.5 10C8.5 10.9283 8.86875 11.8185 9.52513 12.4749C10.1815 13.1313 11.0717 13.5 12 13.5C12.9283 13.5 13.8185 13.1313 14.4749 12.4749C15.1313 11.8185 15.5 10.9283 15.5 10C15.5 9.72386 15.7239 9.5 16 9.5C16.2761 9.5 16.5 9.72386 16.5 10C16.5 11.1935 16.0259 12.3381 15.182 13.182C14.3381 14.0259 13.1935 14.5 12 14.5C10.8065 14.5 9.66193 14.0259 8.81802 13.182C7.97411 12.3381 7.5 11.1935 7.5 10C7.5 9.72386 7.72386 9.5 8 9.5Z" />,
  "Cart"
);

export const PaymentIcon = createSvgIcon(
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 15.75L22.25 15.75C22.6642 15.75 23 15.4142 23 15V4.5C23 4.08579 22.6642 3.75 22.25 3.75L2.75 3.75C2.33579 3.75 2 4.08579 2 4.5V15C2 15.4142 2.33579 15.75 2.75 15.75Z"
      stroke="white"
      strokeLinejoin="round"
    />
    <path d="M3.5 18H21.5M5 20.25H20" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.5 13.5C14.5711 13.5 16.25 11.8211 16.25 9.75C16.25 7.67893 14.5711 6 12.5 6C10.4289 6 8.75 7.67893 8.75 9.75C8.75 11.8211 10.4289 13.5 12.5 13.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 7.5C22.0054 7.5 21.0516 7.10491 20.3483 6.40165C19.6451 5.69839 19.25 4.74456 19.25 3.75M2 7.5C2.49246 7.5 2.98009 7.403 3.43506 7.21455C3.89003 7.02609 4.30343 6.74987 4.65165 6.40165C4.99987 6.05343 5.27609 5.64003 5.46455 5.18506C5.653 4.73009 5.75 4.24246 5.75 3.75M23 12C22.0054 12 21.0516 12.3951 20.3483 13.0983C19.6451 13.8016 19.25 14.7554 19.25 15.75M2 12C2.49246 12 2.98009 12.097 3.43506 12.2855C3.89003 12.4739 4.30343 12.7501 4.65165 13.0983C4.99987 13.4466 5.27609 13.86 5.46455 14.3149C5.653 14.7699 5.75 15.2575 5.75 15.75"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Payment"
);
