import { Box, Button, Grid } from "@mui/material";
import { DateTime } from "luxon";
import { ApiHomeServiceSlot } from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import OutletBookingContext from "pages/OutletPage/outletContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import ModalFooterButtons from "./ClientAddressesModal/ModalFooterButtons";
import DrawerModal from "./DrawerModal";

export function TimeSlotSelectorModal({
  onClose,
  onSelect,
  timeSlots,
  loading,
}: {
  onClose: () => void;
  onSelect: (timeSlot: ApiHomeServiceSlot) => void;
  timeSlots: ApiHomeServiceSlot[];
  loading: boolean;
}) {
  const { t } = useTranslation(["outletPage", "common"]);

  const { selectedDate, selectedPurchasable } = useContext(OutletBookingContext);
  const date = selectedDate?.toFormat("yyyy-MM-dd");

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Nullable<ApiHomeServiceSlot>>(null);

  return (
    <DrawerModal
      title={t("pickYourTimeSlot")}
      open
      onClose={onClose}
      PaperProps={{ sx: { padding: "16px" } }}
    >
      <Box display="flex" flexDirection="column" flexGrow={1} mt={2} overflow="hidden">
        <Grid container spacing={1}>
          {timeSlots.map((timeSlot) => (
            <Grid item key={timeSlot.startTime} xs={4}>
              <Button
                fullWidth
                variant={
                  selectedTimeSlot?.startTime === timeSlot.startTime ? "contained" : "outlined"
                }
                onClick={() => {
                  setSelectedTimeSlot(timeSlot);
                }}
              >
                {DateTime.fromISO(`${date}T${timeSlot.startTime}+03:00`).toFormat("hh:mm a")}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>

      <ModalFooterButtons
        handleCancelClick={onClose}
        handleContinueClick={() => {
          if (selectedTimeSlot && selectedPurchasable) {
            onSelect(selectedTimeSlot);
          }
        }}
        continueButtonProps={{
          disabled: !selectedTimeSlot || loading,
          loading,
        }}
      />
    </DrawerModal>
  );
}
