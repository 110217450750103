import { z } from "zod";

/**
 * This function is used to use proper required error on zod schema if it is empty or is a falsy value. So no matter what the field type is, it will throw the proper required error
 *
 * @returns z.number with proper required error
 */

export const NumberWithProperRequiredError = () =>
  z.number({
    required_error: "mixed.required",
    // WARNING: this is a hack to make the error message appear on the correct field for fields like select which has a weird bug that casts the value from undefined to empty string. Only use this if you are sure that the field were you are sure that the value will always be a number, otherwise don't use this field cause it will not throw correct invalid type error. - Anfal the one who lost hope
    invalid_type_error: "mixed.required",
  });
