import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SliceStatus } from "store/slices/utils";
import createThunkFromApi from "store/utils/createThunkFromApi";

import api from "./areasApi";

export const areasAdapter = createEntityAdapter<Area>({
  selectId: (area) => area.id,
});

export const getAreas = createThunkFromApi("areas/getAreas", api.getAreas);

const initialState = areasAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const areaSlice = createSlice({
  name: "areas",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getAreas.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getAreas.fulfilled, (state, { payload }) => {
        areasAdapter.setAll(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getAreas.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default areaSlice.reducer;
