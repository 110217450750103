import { Button, Stack, Typography } from "@mui/material";
import { LocationPinIcon } from "assets/icons/LocationPinIcon";
import useAddressLabel from "hooks/useAddressLabel";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAreaById, selectProfileAddressById } from "store/selectors";

type AddressDetailsSectionProps = {
  handleChange?: () => void;
  selectedAddressId: number;
};

const AddressDetailsSection = ({ handleChange, selectedAddressId }: AddressDetailsSectionProps) => {
  const { t } = useTranslation(["common", "reviewAppointmentPage"]);

  const address = useSelector(selectProfileAddressById(selectedAddressId));
  const area = useSelector(selectAreaById(address?.area || 0));

  const addressLabel = useAddressLabel(address);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={3}
      border={1}
      borderColor="#D4D6E4"
      p={1.75}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack
          sx={{
            backgroundColor: "#D9DAF7",
            borderRadius: "50%",
            p: 1,
          }}
        >
          <LocationPinIcon
            color="primary"
            sx={{
              height: "16px",
              width: "16px",
            }}
          />
        </Stack>

        <Stack>
          <Typography variant="h4" fontSize="0.875rem">
            {area?.name}
          </Typography>
          <Typography variant="secondary" fontSize="0.75rem" color="#53627C" fontWeight={500}>
            {addressLabel}
          </Typography>
        </Stack>
      </Stack>

      {!!handleChange && (
        <Button variant="text" color="primary" onClick={handleChange}>
          {t("common:change")}
        </Button>
      )}
    </Stack>
  );
};

export default AddressDetailsSection;
