import { RefinementCtx, ZodIssueCode } from "zod";

/**
 * This function is used to make the field required on zod schema if it is empty or is a falsy value
 * @param field - the field to check
 * @param ctx
 *
 */

const makeFieldRequired = (field: any, ctx: RefinementCtx): void => {
  if (!field) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: [`${field}`],
      message: "mixed.required",
    });
  }
};

export default makeFieldRequired;
