import { CartSliceState } from "store/slices/cart/cartSlice";

type CartDictionary = {
  [outletId: number]: CartSliceState;
};

const PEEP_CART = "peep_cart";

class CartManager {
  constructor() {
    this.hydrate();
  }

  setOutletCart(outletId: number, cartState: CartSliceState) {
    const newState = { ...this.outlets, [outletId]: cartState };
    localStorage.setItem(PEEP_CART, JSON.stringify(newState));
  }

  getOutletCart(outletId: number) {
    const outletCart = this.outlets[outletId];

    if (outletCart) return outletCart;

    // If the outlet cart was never initialised
    const initialCartState = generateEmptyCart(outletId);
    this.setOutletCart(outletId, initialCartState);

    return this.outlets[outletId];
  }

  destroyAllCarts() {
    this.initialiseDictionary();
  }

  private get outlets() {
    const stringCarts = localStorage.getItem(PEEP_CART);
    if (!stringCarts) {
      this.initialiseDictionary();
      return {} as CartDictionary;
    }

    const storedCarts = JSON.parse(stringCarts);
    if (typeof storedCarts !== "object") {
      this.initialiseDictionary();
      return {} as CartDictionary;
    }

    return storedCarts as CartDictionary;
  }

  private initialiseDictionary() {
    localStorage.setItem(PEEP_CART, "{}");
  }

  private hydrate() {
    if (!localStorage.getItem(PEEP_CART)) this.initialiseDictionary();
  }
}

const cart = new CartManager();

export default cart;

export function generateEmptyCart(outlet: number): CartSliceState {
  return {
    address: null,
    outlet,
    notes: "",
    articles: [],
    area: null,
    numOfPeople: null,
    activeArticleId: null,
    sessionId: null,
  };
}
