import unionIntervals from "helpers/array/unionIntervals";
import { TIME_FORMAT } from "helpers/datetime/datetimeConstants";
import { Interval } from "luxon";
import { RootState } from "store";

import { selectPackageById } from "../packages/packagesSelectors";
import { selectPackageVariantById } from "../packageVariants/packageVariantsSelectors";
import { selectServiceById } from "../services/servicesSelectors";
import {
  selectServiceVariantById,
  selectServiceVariantLongName,
} from "../serviceVariants/serviceVariantsSelectors";
import { TYPES } from "./../../../pages/OutletPage/constants";
import {
  EarliestArticleData,
  getEarliestCartArticleSlotWithArticleIndex,
} from "./getEarliestArticleSlotWithArticleIndex";
import getEarliestCartArticleSlot from "./getEarliestCartArticleSlot";

export const selectCart = (state: RootState) => {
  const date = selectCartDate(state)?.toISODate() || null;

  return { ...state.cart, date };
};

export const selectAppliedSubscriptions = ({ cart }: RootState) => {
  return cart.articles.map((article) => article.subscriptionPurchaseId);
};

export const selectCartDate = ({ cart }: RootState) => getEarliestCartArticleSlot(cart.articles);

export const selectHomeServiceArea = ({ cart }: RootState) => cart.area;

export const selectHomeServiceNumOfPeople = ({ cart }: RootState) => cart.numOfPeople;

export const selectSessionId = ({ cart }: RootState) => cart.sessionId;

export const selectCartAddress = ({ cart }: RootState) => cart.address;

export const selectCartIsEmpty = (state: RootState) => !state.cart.articles.length;

export const selectCartOutletId = (state: RootState) => state.cart.outlet;

export const selectPurchasableEarliestArticleFromCart =
  (purchasableId: number, purchasableType: TYPES) =>
  ({ cart: { articles } }: RootState) => {
    let earliestSlot: Nullable<EarliestArticleData> = null;

    const modifiedArticles = articles.map((article, index) => ({ ...article, index }));

    if (purchasableType === TYPES.SERVICES) {
      earliestSlot = getEarliestCartArticleSlotWithArticleIndex(
        modifiedArticles.filter((article) => article.serviceVariant === purchasableId)
      );
    } else {
      earliestSlot = getEarliestCartArticleSlotWithArticleIndex(
        modifiedArticles.filter((article) => article.packageVariant === purchasableId)
      );
    }

    return earliestSlot;
  };

export const selectCartSummary = (state: RootState) => {
  let totalPrice = 0;
  let serviceCount = 0;
  let packageCount = 0;
  let hasFromPrice = false;

  state.cart.articles.forEach((article) => {
    const articleDetails = getArticleWithDetails(article, state);

    if (!article.subscriptionPurchaseId) totalPrice += article.totalPrice;

    if (article.serviceVariant) serviceCount++;
    if (article.packageVariant) packageCount++;

    if (articleDetails.isPriceFrom) hasFromPrice = true;
  });

  return { totalPrice, serviceCount, packageCount, hasFromPrice };
};

/**
 * This function must return the intervals[] in the same order as the articles themselves
 */
export const selectCartArticleIntervals = ({ cart: { articles } }: RootState) => {
  return articles.map((article) => article.slots.map(getIntervalFromSlot).reduce(unionIntervals));
};

export const selectCartArticleSummary = (state: RootState) => {
  return state.cart.articles.map((article) => {
    const interval = getArticleInterval(article);

    const articleDetails = getArticleWithDetails(article, state);

    return {
      ...articleDetails,
      startTime: interval.start.toFormat(TIME_FORMAT),
      endTime: interval.end.toFormat(TIME_FORMAT),
    };
  });
};

export const selectCartArticleSummaryByServiceOrPackageVariantId =
  (serviceVariantId?: number, packageVariantId?: number) => (state: RootState) => {
    const articles = state.cart.articles.filter(
      (article) =>
        article.serviceVariant === serviceVariantId || article.packageVariant === packageVariantId
    );

    return articles.map((article) => {
      const interval = getArticleInterval(article);

      const articleDetails = getArticleWithDetails(article, state);

      return {
        ...articleDetails,
        startTime: interval.start.toFormat(TIME_FORMAT),
        endTime: interval.end.toFormat(TIME_FORMAT),
      };
    });
  };

const getArticleInterval = ({ slots }: CartArticle) => {
  const intervals = slots.map(getIntervalFromSlot);
  return intervals.reduce((unionInterval, currentInterval) => currentInterval.union(unionInterval));
};

const getIntervalFromSlot = (slot: CartArticleSlot) =>
  Interval.fromISO(`${slot.start}/${slot.end}`);

const getArticleWithDetails = (article: CartArticle, state: RootState) => {
  if (article.serviceVariant) {
    const serviceVariant = selectServiceVariantById(article.serviceVariant)(state);
    const title = selectServiceVariantLongName(article.serviceVariant)(state);

    if (serviceVariant) {
      return {
        ...article,
        title,
        totalPrice: Number(serviceVariant.price),
        isPriceFrom: serviceVariant.pricingType === "FROM",
      };
    }
  }

  if (article.packageVariant) {
    const packageVariant = selectPackageVariantById(article.packageVariant)(state);

    if (packageVariant) {
      return {
        ...article,
        title: [packageVariant.name],
        totalPrice: Number(packageVariant.price),
        isPriceFrom: false,
      };
    }
  }

  throw new Error(
    "getArticleWithDetails couldn't find article details, make sure you are using it only when you are sure articles exist"
  );
};

export const getDoArticlesHaveServiceWithPricingFrom = (state: RootState) => {
  const serviceArticles = state.cart.articles.filter((article) => !!article.serviceVariant);

  const variantsWithDetails = serviceArticles.map((serviceArticle) =>
    selectServiceVariantById(serviceArticle.serviceVariant || "")(state)
  );

  return variantsWithDetails.some((article) => article?.pricingType === "FROM");
};

export const getCartArticlesWithDetails = (state: RootState) => {
  const cartArticles = state.cart.articles;

  const articlesWithDetails = cartArticles.map((cartArticle) => {
    if (!!cartArticle.serviceVariant) {
      return {
        ...selectServiceById(
          selectServiceVariantById(cartArticle.serviceVariant || "")(state)?.service || 0
        )(state),
        isService: true,
      };
    }

    return {
      ...selectPackageById(
        selectPackageVariantById(cartArticle.packageVariant || "")(state)?.package || 0
      )(state),
      isPackage: true,
    };
  });

  return articlesWithDetails;
};

export const getUniqueCartArticlesWithDetails = (state: RootState) => {
  const articlesWithDetails = getCartArticlesWithDetails(state);

  const serviceArticles = articlesWithDetails.filter((article) => "isService" in article);
  const uniqueServiceArticles = [
    ...new Map(serviceArticles.map((item) => [item["id"], { ...item, isPackage: false }])).values(),
  ];

  const packageArticles = articlesWithDetails.filter((article) => "isPackage" in article);

  const uniquePackageArticles = [
    ...new Map(
      packageArticles.map((item) => [item["id"], { ...item, isService: false, isPackage: true }])
    ).values(),
  ];

  const uniqueArticles = uniqueServiceArticles.concat(uniquePackageArticles);

  return uniqueArticles;
};

export const getCartDownPayment = (state: RootState) => {
  const cart = selectCart(state);
  const articles = cart.articles;
  return articles.reduce((acc, article) => {
    return acc + article.amountToBePaid;
  }, 0);
};

export const selectHasFilledHomeServiceAppointmentDetails = ({ cart }: RootState) =>
  cart.area !== null && cart.numOfPeople !== null;
