import { Pencil } from "@bookpeep/ui";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";

type HomeServiceAppointmentDetailsItemProps = {
  onClick: () => void;
  icon: React.ReactNode;
  title: string;
};

export default function HomeServiceAppointmentDetailsItem({
  onClick,
  icon,
  title,
}: HomeServiceAppointmentDetailsItemProps) {
  return (
    <Grid
      item
      xs={5.9}
      display="flex"
      flexDirection="row"
      bgcolor="#F7F8FD"
      alignItems="center"
      p={1}
      borderRadius={2}
      justifyContent="space-between"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        {icon}
        <Typography fontSize="0.75rem" fontWeight={700}>
          {title}
        </Typography>
      </Box>
      <Pencil color="primary" style={{ fontSize: "1.8rem" }} />
    </Grid>
  );
}
