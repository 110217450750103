import OutletBookingContext from "pages/OutletPage/outletContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectOrganization, selectSessionId } from "store/selectors";
import cartApi from "store/slices/cart/cartApi";
import { clearCartArticles } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

import HomeServiceConfirmationModal from "./HomeServiceConfirmationModal";

type HomeServiceConfirmDeleteArticleModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const HomeServiceConfirmDeleteArticleModal = ({
  open,
  onClose,
  onConfirm,
}: HomeServiceConfirmDeleteArticleModalProps) => {
  const { t } = useTranslation("outletPage");

  const organization = useSelector(selectOrganization);

  const { setSelectedPurchasable, setSelectedPurchasablePackage } =
    useContext(OutletBookingContext);

  const sessionId = useSelector(selectSessionId);

  const dispatch = useDispatch();

  const handleProceed = async () => {
    dispatch(clearCartArticles());
    if (sessionId) await cartApi.clearSessionDrafts(sessionId);

    setSelectedPurchasable(null);
    setSelectedPurchasablePackage(null);

    if (organization?.id && organization?.name) {
      trackEvent("Lost Cart", { id: organization.id, name: organization.name }, {});

      trackEvent(
        "Cart Initiated",
        { id: organization.id, name: organization.name },
        { isResourceSelected: false }
      );
    }

    onConfirm();
  };

  return (
    <HomeServiceConfirmationModal
      description={t("clearCartWarningDeleteArticle")}
      onClose={onClose}
      onConfirm={handleProceed}
      open={open}
    />
  );
};
export default HomeServiceConfirmDeleteArticleModal;
