import { RootState } from "store";

import { zonesAdapter } from "./zoneSlice";

const selectZonesState = (state: RootState) => state.zones;

const { selectById, selectAll } = zonesAdapter.getSelectors(selectZonesState);

export const selectZoneById = (id: Nullable<number | string>) => (state: RootState) =>
  selectById(state, id || "");

export const selectAllZones = selectAll;
