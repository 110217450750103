import { Grid } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { useSelector } from "store";
import {
  selectAllPackageVariants,
  selectCart,
  selectOutletIdBySlug,
  selectPackagesByOutletId,
} from "store/selectors";

import PurchasableCard from "./PurchasableCard";
import PurchasedArticleCard from "./PurchasableCard/PurchasedArticleCard";

const PackageList = () => {
  const paramOutlet = useParamOutlet(true);

  const outletSlug = paramOutlet?.slug || "";

  const outlet = useSelector(selectOutletIdBySlug(outletSlug));

  const allPackages = useSelector(selectPackagesByOutletId(outlet || 0));

  const cart = useSelector(selectCart);

  const allPackageVariants = useSelector(selectAllPackageVariants);
  const packageVariantsInCart = cart.articles.map((article) =>
    allPackageVariants.find((packageVariant) => packageVariant.id === article.packageVariant)
  );

  const packagesInCart = packageVariantsInCart.map((packageVariant) => packageVariant?.package);

  return (
    <>
      {packageVariantsInCart.map((packageVariant) => (
        <Grid key={packageVariant?.id ?? 0} item xs={12}>
          <PurchasedArticleCard
            packageId={packageVariant?.package}
            variantId={packageVariant?.id}
          />
        </Grid>
      ))}
      {allPackages
        .filter((pkg) => !packagesInCart.includes(pkg.id))
        .map((pkg) => (
          <Grid key={pkg?.id ?? 0} item xs={12}>
            <PurchasableCard packageId={pkg.id} />
          </Grid>
        ))}
    </>
  );
};

export default PackageList;
