import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import { CallToActionLink } from "components/CallToActionLink";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/marketplace";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import dontForwardProps from "helpers/styles/dontForwardProps";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import { ReactNode, memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";

type Props = {
  title: string;
  subtitle?: ReactNode;

  shouldShowPrivacyDisclaimer?: boolean;

  children: ReactNode;

  footer?: ReactNode;
};

function AuthenticationPageLayout({ title, subtitle, children, footer }: Props) {
  const { t } = useTranslation("misc");
  const { isAndroidDevice } = useDeviceDetection();
  const organization = useSelector(selectOrganization);

  return (
    <>
      <Grid container bgcolor="bg.secondary" p={3} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" mb={1}>
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="primary" color="text.label">
              {subtitle}
            </Typography>
          )}
        </Grid>

        {children}
      </Grid>

      <Grid container p={3} spacing={3}>
        {footer && (
          <Grid item xs={12} textAlign="center">
            {footer}
          </Grid>
        )}
      </Grid>

      {organization.isMpVisible && (
        <Banner
          bgcolor="#C6C8F3"
          imgUrl="/images/LoginDownloadAppBanner.svg"
          height="150px"
          dir="rtl"
          p={2}
          gap={2}
        >
          <Stack width="fit-content" alignItems="flex-end" justifyContent="center" dir="rtl">
            <Typography variant="h4" color="text.primary">
              {t("loginOnlyOnce")}
            </Typography>

            <Stack flexDirection="row" alignItems="center">
              <Box>
                <img alt="" src="/images/download-arrow.svg" height="60px" />
              </Box>

              <CallToActionLink
                href={organization.appLink || getUseAppLink(isAndroidDevice)}
                paddingY={0.5}
                paddingX={1.5}
              >
                {t("downloadApp")}
              </CallToActionLink>
            </Stack>

            <Box display="flex" flexDirection="row" gap={0.5}>
              <a href={APP_STORE_LINK}>
                <img
                  alt="download-on-app-store"
                  src="/images/DownloadAppOnAppStore.svg"
                  height="16px"
                />
              </a>

              <a href={PLAY_STORE_LINK}>
                <img
                  alt="download-on-google-play"
                  src="/images/DownloadAppOnGooglePlay.svg"
                  height="16px"
                />
              </a>
            </Box>
          </Stack>
        </Banner>
      )}
    </>
  );
}

export default memo(AuthenticationPageLayout);

const Banner = styled(
  Box,
  dontForwardProps("imgUrl")
)<{ imgUrl: string }>(({ imgUrl }) => ({
  backgroundImage: `url(${imgUrl})`,
  backgroundPosition: "bottom right",
  backgroundSize: "auto 130%",
  backgroundRepeat: "no-repeat",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));
