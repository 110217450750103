import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllAreas, selectAreAreasLoading } from "store/selectors";
import { selectAllCities } from "store/slices/cities/citySelectors";

import SearchableSelectField, { Option, SearchableSelectFieldProps } from "./SearchableSelectField";

export type AreaAutocompleteAdditionalProps = {
  shouldApplyGroupByCity?: boolean;
};

export type AreaAutocompleteProps = Optional<SearchableSelectFieldProps, "options" | "label"> &
  AreaAutocompleteAdditionalProps;

const AreaAutocomplete = ({
  shouldApplyGroupByCity,
  ...searchableSelectFieldProps
}: AreaAutocompleteProps) => {
  const { t } = useTranslation("common");
  const { value } = searchableSelectFieldProps;

  const areas = useSelector(selectAllAreas);
  const cities = useSelector(selectAllCities);

  const areAreasLoading = useSelector(selectAreAreasLoading);

  const selectedArea = areas.find((area) => area.id === value);

  const getGroupByLabelCityName = (area: Area) =>
    cities.find((city) => city.id === area.city)?.name || undefined;

  const options: Option[] = areas.map((area) => ({
    value: area.id,
    label: area.name,
    groupBy: getGroupByLabelCityName(area),
  }));

  const handleGroupBy = (option: Option) => {
    return option.groupBy || "";
  };

  const groupBy = (option: Option) => option.groupBy;

  // Sort options based on the groupBy function
  const sortedOptions: Option[] = options.sort((a, b) => {
    const groupByA = groupBy(a);
    const groupByB = groupBy(b);

    if (groupByA === groupByB) {
      return 0;
    }

    if (groupByA && groupByB) {
      return groupByA.localeCompare(groupByB);
    }

    return groupByA ? -1 : 1;
  });

  return (
    <SearchableSelectField
      disablePortal={false}
      label={t("common:area")}
      {...searchableSelectFieldProps}
      value={selectedArea?.id || null}
      options={sortedOptions}
      areOptionsLoading={areAreasLoading}
      groupBy={shouldApplyGroupByCity ? handleGroupBy : undefined}
    />
  );
};

export default AreaAutocomplete;
