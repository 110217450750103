import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DrawerModal from "./DrawerModal";
import SearchField from "./SearchField";
import ModalFooterButtons from "./ClientAddressesModal/ModalFooterButtons";

type AreaSelectorModalProps = {
  areas: Area[];
  cities: City[];
  zones: Zone[];
  selectedAreaId: Nullable<number>;
  onSelect: (area: number) => void;
  onClose: () => void;
};

export function AreaSelectorModal({
  areas,
  cities,
  zones,
  onClose,
  onSelect,
  selectedAreaId,
}: AreaSelectorModalProps) {
  const { t } = useTranslation(["outletPage", "common"]);

  const [query, setQuery] = useState("");
  const [selectedArea, setSelectedArea] = useState<Nullable<number>>(selectedAreaId);

  const filteredAreas = areas
    .filter((area) => area.name.toLowerCase().includes(query.toLowerCase()))
    .filter((area) => zones.find((zone) => zone.areas.includes(area.id)));

  const groupedAreas = filteredAreas.reduce((acc, area) => {
    if (!acc[area.city]) {
      // eslint-disable-next-line no-param-reassign
      acc[area.city] = [];
    }
    acc[area.city].push(area);
    return acc;
  }, {} as Record<number, Area[]>);

  return (
    <DrawerModal
      title={t("pickYourArea")}
      open
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "80vh", maxHeight: "80vh", padding: "16px" } }}
    >
      <SearchField
        variant="outlined"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onClear={() => {
          setQuery("");
        }}
      />
      <Box mt={2} display="flex" flexDirection="column" flexGrow={1} overflow="hidden">
        <Box flexGrow={1} overflow="auto">
          {Object.entries(groupedAreas).map(([city, areas]) => (
            <Box key={city} mt={1} gap={1} display="flex" flexDirection="column">
              <Typography
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  color: "#6C798F",
                }}
              >
                {cities.find((c) => c.id === Number(city))?.name}
              </Typography>
              <Box pl={1} flexDirection="column" display="flex" gap={1}>
                {areas.map((area) => (
                  <Box
                    key={area.id}
                    bgcolor={area.id === selectedArea ? "#F7F8FD" : "white"}
                    p={1}
                    borderRadius={2}
                    onClick={() => {
                      setSelectedArea(area.id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      fontSize="0.875rem"
                      color={area.id === selectedArea ? "primary" : undefined}
                      fontWeight={area.id === selectedArea ? 700 : 500}
                    >
                      {area.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <ModalFooterButtons
        handleCancelClick={onClose}
        handleContinueClick={() => {
          if (selectedArea) {
            onSelect(selectedArea);
          }
        }}
        continueButtonProps={{
          disabled: !selectedArea,
        }}
      />
    </DrawerModal>
  );
}
