import { Remove } from "@bookpeep/ui";
import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import ArticleItemRedeemableSubscriptions from "pages/BookingPage/ServiceBookingPage/ArticleItemRedeemableSubscriptions";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveSubscriptionByOutletIdAndItemId,
  selectOrganization,
  selectServiceVariantById,
  selectServiceVariantLongName,
} from "store/selectors";
import { ServiceVariantPricingOption } from "types/ServiceVariantPricingOption";
import { RedeemableSubscriptionItemOption } from "types/Subscription";
import { checkIsHomeServiceOrganization } from "utils/organization";

import AppointmentArticleContainer from "../AppointmentArticleContainer";
import ArticleStaffSelector from "./ArticleStaffSelector";

type AppointmentServiceArticleProps = {
  selectedDate: DateTime;
  outletId: number;
  article: ServiceCartArticle;
  shouldShowDeleteButton?: boolean;
  handleArticleDelete: () => void;
  handleArticleUpdate: (slot: CartArticleSlot) => void;
  handleSubscriptionPurchaseSelect: (subscriptionPurchaseId: Nullable<number>) => void;
  shouldShowNumberOfPeopleCount?: boolean;
};

const AppointmentServiceArticle = ({
  selectedDate,
  outletId,
  article,
  shouldShowDeleteButton = true,
  handleArticleDelete,
  handleArticleUpdate,
  handleSubscriptionPurchaseSelect,
  shouldShowNumberOfPeopleCount,
}: AppointmentServiceArticleProps) => {
  const { t } = useTranslation();

  const serviceVariant = useSelector(selectServiceVariantById(article.serviceVariant));
  const serviceName = useSelector(selectServiceVariantLongName(serviceVariant?.id || -1));
  const org = useSelector(selectOrganization);

  const subscriptions = useSelector(
    selectActiveSubscriptionByOutletIdAndItemId(
      outletId,
      article.serviceVariant,
      RedeemableSubscriptionItemOption.ServiceVariants
    )
  );

  if (!serviceVariant) return <CentredSpinnerBox />;

  const { pricingType, uptoPrice } = serviceVariant;

  const shouldShowPriceFrom = pricingType === ServiceVariantPricingOption.From;

  const shouldShowRedeemableSubscriptions = subscriptions?.length > 0;

  const slot = article.slots.length ? article.slots[0] : null;

  const handleToggleSubscriptionActivation = (subscriptionId: Nullable<number>) => {
    handleSubscriptionPurchaseSelect(
      article.subscriptionPurchaseId === subscriptionId ? null : subscriptionId
    );
  };

  const isHomeService = checkIsHomeServiceOrganization(org.industry!);

  return (
    <AppointmentArticleContainer>
      <Box display="flex" flexDirection="column" justifyContent="center" width="100%" rowGap={2}>
        <Box display="flex" gap={1} justifyContent="space-between" width="100%">
          <Box display="flex" flexDirection="column" rowGap={1} height="100%" flexGrow={1}>
            <Typography variant="h4">
              {t(serviceName[0])}
              {serviceName[1] ? ` - ${t(serviceName[1])}` : ""}
            </Typography>

            {slot && (
              <Typography variant="primary">{`${fromTofyTimes(
                DateTime.fromISO(slot.start),
                DateTime.fromISO(slot.end)
              )} • ${getFormattedDuration(serviceVariant.duration)}`}</Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="center" alignItems="flex-start">
            <Stack direction="row" alignItems="start">
              <PriceBox
                quantity={
                  shouldShowNumberOfPeopleCount ? Number(article.numberOfPeople) : undefined
                }
                isFree={!!article.subscriptionPurchaseId}
                value={Number(serviceVariant.price)}
                decimalStrategy="trim_zeros"
                shouldShowPriceFrom={shouldShowPriceFrom}
                sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
                variant="h4"
                textSx={{ color: "#0A1F44", fontWeight: 500 }}
                upToPrice={Number(uptoPrice)}
                flexDirection={shouldShowNumberOfPeopleCount ? "row" : "column"}
              />
            </Stack>

            {shouldShowDeleteButton && (
              <Remove
                sx={{ fontSize: "1.5rem", color: "error.main", cursor: "pointer" }}
                onClick={handleArticleDelete}
              />
            )}
          </Box>
        </Box>

        {slot && !isHomeService && (
          <ArticleStaffSelector
            isService
            slot={slot}
            blockedEmployeeIds={[]}
            handleArticleUpdate={handleArticleUpdate}
          />
        )}

        {shouldShowRedeemableSubscriptions && (
          <Box display="flex" py={2} justifyContent="space-between">
            <Grid container spacing={1}>
              <ArticleItemRedeemableSubscriptions
                subscriptions={subscriptions}
                appliedSubscription={article.subscriptionPurchaseId}
                handleToggleSubscriptionActivation={handleToggleSubscriptionActivation}
                selectedDate={selectedDate || DateTime.now()}
              />
            </Grid>
          </Box>
        )}
      </Box>
    </AppointmentArticleContainer>
  );
};

export default AppointmentServiceArticle;
